import { Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/services/api-service.service';
import { forkJoin } from 'rxjs';
import { ContractModel } from 'src/app/models/contract';
import { SnackService } from 'src/app/services/snack.service';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import { DataTablePage } from 'src/app/models/datatable-page';
import { NgForm } from '@angular/forms';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-contract',
  templateUrl: './contract.component.html',
  styleUrls: ['./contract.component.scss']
})
export class ContractComponent implements OnInit {
  
  @ViewChild('smartFilteringForm') smartFilteringForm : NgForm;
  isLoading: boolean
  showSmartFiltering: boolean = false
  pagedContracts: {
    data: ContractModel[],
    page: DataTablePage
  }

  // Stats
  usersCount: number
  activeUsers: number
  onlineUsers: number
  expiredUsers: number
  expiredSoonUsers: number
  totalBalance: string
  rewardPoints: number
  
  wdOutstandingDebts: number
  wdOutstandingClaims: number
  wdSystemTime: number


  //Smart Filter
  filterData: {}
  profiles: []
  parents: []

  // Datatable
  ColumnMode = ColumnMode;
  SelectionType = SelectionType;
  page: DataTablePage;
  auth: {}

  columns = [
    { prop: 'username', name: 'اسم المستخدم'},
    { prop: 'firstname', name: 'الاسم الاول'},
    { prop: 'lastname', name: 'الاسم الاخير'},
    { prop: 'contract_id', name: 'رقم العقد'},
    { prop: 'status', name: 'الحالة', sortable: false},    
    { prop: 'expiration', name: 'تاريخ الإنتهاء'},
    { prop: 'parent_username', name: 'تابع إلي'},
    { prop: 'profile_details.name', name: 'الباقة'},
    { prop: 'loan_balance', name: 'القروض'},
    { prop: 'daily_traffic_details.traffic', name: 'الاستهلاك اليومي', sortable: false},
    { prop: 'remaining_days', name: 'الايام المتبقية'}
  ];

  allColumns = [
    { prop: '0', name: 'تحديد الكل', sortable: false, active: false},
    { prop: 'id', name: '#'},
    { prop: 'contract_id', name: 'رقم العقد'},
    { prop: 'status', name: 'الحالة', sortable: false},
    { prop: 'username', name: 'اسم المستخدم'},
    { prop: 'firstname', name: 'الاسم الاول'},
    { prop: 'lastname', name: 'الاسم الاخير'},
    { prop: 'expiration', name: 'تاريخ الإنتهاء'},
    { prop: 'parent_username', name: 'تابع إلي'},
    { prop: 'profile_details.name', name: 'الباقة'},
    { prop: 'loan_balance', name: 'القروض'},
    { prop: 'daily_traffic_details.traffic', name: 'الاستهلاك اليومي', sortable: false},
    { prop: 'remaining_days', name: 'الايام المتبقية'},
    { prop: 'balance', name: 'الرصيد'},
    { prop: 'city', name: 'المدينة'},
    { prop: 'static_ip', name: 'Static IP'},
    { prop: 'notes', name: 'ملاحظات'},
    { prop: 'last_online', name: 'اخر اتصال'},
    { prop: 'company', name: 'الشركة'},
    { prop: 'simultaneous_sessions', name: 'مرات الدخول'},
    { prop: 'used_traffic', name: 'البيانات المستخدمة', sortable: false},
    { prop: 'phone', name: 'الهاتف'},
    { prop: 'address', name: 'العنوان'},
    { prop: 'created_at', name: 'تاريخ الإضافة'},
    { prop: 'available_traffic', name: 'البيانات المتبقية', sortable: false},
    { prop: 'national_id', name: 'الرقم الوطني'},
    { prop: 'mikrotik_ipv6_prefix', name: 'IPv6 Prefix'},
    { prop: 'site_details.site_name', name: 'Site'}

  ];

  constructor(private api: ApiService, private snack: SnackService, private router: Router, private titleService: Title) {
    this.titleService.setTitle("الأفق الرقمي لخدمات الإنترنت - نظام إدارة عقود المشتركين");

    let userColumns = localStorage.getItem('contractPageColumns')
    if(userColumns) {
      let clientColumns  = JSON.parse(localStorage.getItem('contractPageColumns'))    
      this.columns = clientColumns
    } else {
      localStorage.setItem('contractPageColumns', JSON.stringify(this.columns))
    }

    this.page = {
      pageNumber: 0,
      size: 10,
      totalElements: null,
      totalPages: null
    },
    this.pagedContracts = {
      data: [],
      page: this.page
    }
   }

  ngOnInit() {
    this.getContracts({ offset: 0 }, {sort_by: 'contract_id', direction: 'desc'});
    this.getStatistics();
    this.chackpasswordforce();
  }

  toggle(col) {    
    if(col.prop == 0) {
      if(this.allColumns[0]['active']) {
        this.columns = []
        this.allColumns[0]['active'] = false
      } else {
        this.allColumns[0]['active'] = true
        this.columns = this.allColumns.slice(1);
      }
      localStorage.setItem('contractPageColumns', JSON.stringify(this.columns))
      return false
    }

    const isChecked = this.isChecked(col);

    if (isChecked) {
      this.columns = this.columns.filter(c => {
        return c.name !== col.name;
      });
      localStorage.setItem('contractPageColumns', JSON.stringify(this.columns))
    } else {
      this.columns = [...this.columns, col];
      localStorage.setItem('contractPageColumns', JSON.stringify(this.columns))
    }
  }

  isChecked(col) {
    return (
      this.columns.find(c => {
        return c.name === col.name;
      }) !== undefined
    );
  }


  getStatistics() {
    const widgetBase = 'widgetData/internal/'
    const wd_users_count = this.api.get(widgetBase + 'wd_users_count')
    const wd_users_active_count = this.api.get(widgetBase + 'wd_users_active_count')
    const wd_users_online = this.api.get(widgetBase + 'wd_users_online')
    const wd_users_expired_count = this.api.get(widgetBase + 'wd_users_expired_count')
    const wd_users_expiring_in_3_days = this.api.get(widgetBase + 'wd_users_expiring_in_3_days')
    const wd_balance = this.api.get(widgetBase + 'wd_balance')
    const wd_reward_points = this.api.get(widgetBase + 'wd_reward_points')

    const wd_outstanding_debts = this.api.get(widgetBase + 'wd_outstanding_debts')
    const wd_outstanding_claims = this.api.get(widgetBase + 'wd_outstanding_claims')
    const wd_system_time = this.api.get(widgetBase + 'wd_system_time')

    forkJoin([
      wd_users_count,
      wd_users_active_count,
      wd_users_online,
      wd_users_expired_count,
      wd_users_expiring_in_3_days,
      wd_balance,
      wd_reward_points,
      wd_outstanding_debts,
      wd_outstanding_claims,
      wd_system_time
    ]).subscribe(results => {
      this.usersCount = results[0]['data']
      this.activeUsers = results[1]['data']
      this.onlineUsers = results[2]['data']
      this.expiredUsers = results[3]['data']
      this.expiredSoonUsers = results[4]['data']
      this.totalBalance = results[5]['data']
      this.rewardPoints = results[6]['data']

      this.wdOutstandingDebts = results[7]['data']
      this.wdOutstandingClaims = results[8]['data']
      this.wdSystemTime = results[9]['data']



    });
  }

  onSelect(selectedRow) {
    let id = selectedRow['selected'][0]['id'];
    this.router.navigateByUrl('admin/contract/' + id)
  }
  
  onSort(selectedColumn) { 
    let sortBy =  selectedColumn.sorts[0]['prop']
    
    switch (sortBy) {
      case 'parent_username' : sortBy = 'parent_id'
      break;
      case 'profile_details.name' : sortBy = 'profile_id'  
      break;
      case 'site_details.site_name' : sortBy = 'site_id'
      break;
    }

    let SortDirection =  selectedColumn.sorts[0]['dir']
    this.getContracts({ offset: 0 }, {sort_by: sortBy, direction: SortDirection})
  }

  getContracts(pageInfo, sort?: {sort_by, direction}) {        
    this.isLoading = true

    this.page.pageNumber = pageInfo.offset;
    const contractsRequestData = {"page":this.page.pageNumber+1,"count":this.page.size,"sortBy":sort.sort_by,"direction":sort.direction,"columns":["id","username", "contract_id", "firstname", "lastname", "expiration", "parent_username", "name", "loan_balance", "traffic", "available_traffic", "used_traffic", "remaining_days", "site_name"], ...this.filterData}
      
    this.api.post('index/user', contractsRequestData, true).subscribe(
      res => {
        this.isLoading = false
        this.pagedContracts.data = res['data']
        this.pagedContracts.page.size = res['per_page']
        this.pagedContracts.page.totalElements = res['total']
        this.pagedContracts.page.totalPages = res['last_page']
      }, err => {
        this.snack.showError('There is something wrong. Please check the console!')
        this.isLoading = false
        console.log(err)
      })
  }  

  getProfiles(): void {
    this.isLoading = true
    this.api.get('list/profile/0').subscribe(
      res => {
        this.profiles = res['data']
        this.isLoading = false
      },
      err => {
        this.snack.showError('There is something wrong. Please check the console!')
        this.isLoading = false
        console.log(err);
      }
    )
  }

  getParents(): void {
    this.isLoading = true
    this.api.get('index/manager').subscribe(
      res => {
        this.parents = res['data']
        this.isLoading = false
      },
      err => {
        this.snack.showError('There is something wrong. Please check the console!')
        this.isLoading = false
        console.log(err);
      }
    )
  }

  toggleSmartFiltering() {
    if(this.showSmartFiltering) {
      this.showSmartFiltering = false
    } else {
      this.getProfiles()
      this.getParents()
      this.showSmartFiltering = true
    }
  }

  smartFiltering(filterForm: NgForm) {
    let searchText = filterForm['filterSearchText']
    let status = filterForm['filterStatus'] || -1
    let connection = filterForm['filterConnection'] || -1
    let profile_id = filterForm['filterProfile'] || -1
    let parent_id = filterForm['filterParentId'] || -1
    let mac = filterForm['filterMac']
    let sub_users = filterForm['filterSubUsers'] == "false" ? "" : true
    let filterData = { "search": searchText, "status":status, "connection":connection, "profile_id":profile_id, "parent_id":parent_id, sub_users:sub_users, "mac":mac }
    this.filterData = filterData
    this.getContracts({ offset: 0 }, {sort_by: 'username', direction: 'asc'})
  }

  resetFilter()
  {
    this.filterData = {}
    this.smartFilteringForm.reset();
  }

  chackpasswordforce(): void {
    this.isLoading = true
    this.api.get('auth').subscribe(
      res => {
        this.auth = res
        if (res.client.force_change_password === 1) {
          this.router.navigate(['admin/changePassword'])
          this.snack.showError('يجب تغيير كلمة السر للمتابعة!')

        }

        this.isLoading = false
      },
      err => {
        this.snack.showError('There is something wrong. Please check the console!')
        this.isLoading = false
        console.log(err);
      }
    )
  }

}
