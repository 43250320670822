const production: boolean = true;
  
const prod_env = {
  production: true,
 // API_URL:'http://demo4.sasradius.com/admin/api/index.php/api/',
  API_URL:'https://portal.alofoq.ly/admin/api/index.php/api/',
 // API_URL:'https://172.17.254.13/admin/api/index.php/api/',
  
  PRIVATEKEY: 'abcdefghijuklmno0123456789012345' 
};

const dev_env = {
  production: false,
  //API_URL:'http://demo4.sasradius.com/admin/api/index.php/api/',
   API_URL:'https://portal.alofoq.ly/admin/api/index.php/api/',
  PRIVATEKEY: 'abcdefghijuklmno0123456789012345' 
};

export const environment = (production ? prod_env : dev_env);