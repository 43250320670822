<div class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" [id]="modalName" tabindex="-1" aria-labelledby="renewModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
        <div #modalContent class="modal-content  glassmorphism-card rounded-lg px-2 px-lg-5 py-3" style="overflow-y: auto;">
            <div class="modal-header  border-bottom-0 pb-0">
                <button title="تحديث بيانات المشترك" (click)="refreshdata(contractDetails.id)"
                class="btn btn-sm btn-alofoq me-2">
                   <i class="fa fa-sync "></i>
                </button>

                <i class="fa fa-bolt pe-2"></i>
                تفعيل العقد 
                ( {{contractDetails.username}} , {{contractDetails.firstname}} {{contractDetails.lastname}} )
                

                <button (click)="changeStatusToClosed(modalName)" #closeButton type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" title="إغلاق"></button>
            </div>
               <hr>
               <div  *ngIf="(isEdit && !isLoading) || (!isEdit)">
                <div class="row">
                    <ng-container>
                        <div class="col-12 col-xl">
                            <div >
                                <div class="card-body">
                                    <div class="row text-center text-xl-start">
                                        <div class="col-md-4 col-lg-3  col-6 ">
                                            <small class="d-block">
                                                <b>الباقة الحالية</b>
                                            </small>
                                            <div dir="ltr">
                                                <small>{{ActivateData.profile_name}}</small>                                    
                                            </div>
                                        </div>
                                        <div class="col-md-4 col-lg-3  col-6 "  >
                                            <small class="d-block">
                                                <b>سعر الوحدة</b>
                                            </small>
                                            <div dir="ltr">
                                                <ul class="list-unstyled">
                                                    <li >
                                                        {{ActivateData.user_price}} LYD
            
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>                                                                                                                           
                                        <div class="col-md-4 col-lg-3  col-6 ">
                                            <small class="d-block">
                                                <b>صلاحية الباقة</b>
                                            </small>
                                            <div dir="ltr">
                                                <small>{{ActivateData.profile_duration}}</small>                                    
                                            </div>
                                        </div>
                                        <div class="col-md-4 col-lg-3  col-6 "  >
                                            <small class="d-block">
                                                <b>كمية البيانات</b>
                                            </small>
                                            <div dir="ltr">
                                                <ul class="list-unstyled">
                                                    <li >
                                                        {{ActivateData.profile_traffic}}
            
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>                                                                                                                           
                                        <div class="col-md-4 col-lg-3  col-6 ">
                                            <small class="d-block">
                                                <b>رصيد المدير</b>
                                            </small>
                                            <div dir="ltr">
                                                <small>{{ActivateData.manager_balance}}</small>                                    
                                            </div>
                                        </div>
                                        <div class="col-md-4 col-lg-3  col-6 "  >
                                            <small class="d-block">
                                                <b>رصيد المشترك</b>
                                            </small>
                                            <div dir="ltr">
                                                <ul class="list-unstyled">
                                                    <li >
                                                        {{ActivateData.user_balance}}
            
                                                    </li>
                                                </ul>
                                            </div>
                                    </div>                                                                                                                           
                                </div>
                                </div>
                                <hr>
                                <form #acform="ngForm" (ngSubmit)="onFormSubmit(acform)">
                                        <div class="card-body">
                                            <div class="row gy-4">
                                                <div class="col-lg-4">
                                                    <label class="w-100" required>
                                                        طريقة التفعيل
                                                        <select  required class="form-select mt-2" 
                                                            [(ngModel)]="ActivateData.method" name="method" [value]="credit"
                                                            #method="ngModel">
                                                            <option  value="credit" *ngIf="checkPermission('prm_users_activate_credit')">رصيد المدير </option>
                                                            <option value="user_balance" *ngIf="checkPermission('prm_users_activate_user_balance')" >رصيد المشترك</option>
                                                        </select>
                                                    </label>
                                                    <small class="text-danger"
                                                    *ngIf="method.touched && method.errors?.required || acform.submitted && method.errors?.required">
                                                    * الرجاء إدخال القيمة المطلوبة
                                                </small>
                                                </div>            
                                                <div class="col-lg-4">
                                                    <label class="w-100">
                                                        ملاحظات
                                                        <input type="text" class="form-control mt-2"
                                                            [(ngModel)]="ActivateData.comments"
                                                            name="comments"
                                                            #comments="ngModel" dir="ltr">
                                                    </label>
                                                </div>
                                                        <input type="hidden"  
                                                            [(ngModel)]="contractDetails.id"
                                                            name="user_id"
                                                            #user_id="ngModel" dir="ltr">
                                                            <div class="col-lg-12">
                                                                <div class="form-check form-switch">
                                                                    <label class="form-check-label" >
                                                                        مدفوع
                                                                    </label>
                                                                    <input  class="form-check-input" type="checkbox" id="money_collected"
                                                                    [(ngModel)]="ActivateData.money_collected" name="money_collected"
                                                                    #money_collected="ngModel" >
                    
                                                                    </div>
                                                            </div>
                                                                    <input  class="form-check-input" type="hidden" id="money_collected"
                                                                    [(ngModel)]="ActivateData.money_collected" name="money_collected"
                                                                    #money_collected="ngModel" >
                    
                                             <div class="col-lg-12">
                                                    <div class="form-check form-switch">
                                                        <label class="form-check-label" for="issue_invoice">
                                                            اصدار فاتورة
                                                        </label>
                                                        <input class="form-check-input" type="checkbox" id="issue_invoice"
                                                            [(ngModel)]="ActivateData.issue_invoice" name="issue_invoice"
                                                            #issue_invoice="ngModel">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                <div class="card-body">
                                    <form #acForm="ngForm" (ngSubmit)="onSubmit(acForm)">    
                                        <div class=" text-center text-sm-end">
                                            <hr>
                                            <button type="submit" [disabled]="buttonDisabled"  class="btn btn-sm btn-alofoq">
                                            
                                                <ng-container >
                                                    <ng-container >
                                                        <i class="fa fa-bolt pe-2"></i>
                                                        تفعيل العقد
                                                    </ng-container>
                                                </ng-container>
                                            </button>
                                        </div>
                
                                    </form>
                
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            



        </div>
    </div>
</div>