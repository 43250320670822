import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'contractStatus'
})
export class ContractStatusPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let result = {status: "-", bootstrapClass: "body"}
    if(value['traffic'] == true && value['uptime'] == true) {
      if(value['expiration'] == true && value['status'] == true) {
        result = {status: "فعال", bootstrapClass: 'success'}
      } else if(value['expiration'] == false && value['status'] == false) {
        result = {status: "منتهي الصلاحية", bootstrapClass: 'warning'}
      }
    } else if (value['expiration'] == true && value['status'] == true && value['traffic'] == false && value['uptime'] == true) {
      result = {status: "مستهلك", bootstrapClass: 'lemon'}
    } else if (value['expiration'] == false && value['status'] == false && value['traffic'] == false && value['uptime'] == true) {
      result = {status: "الباقة مكتملة", bootstrapClass: 'success'}
    }    
    
    return result;
  }

}
