import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'menuSorting'
})
export class MenuSortingPipe implements PipeTransform {


  transform(array: any[], sortBy: string): any[] {
    const sortOrder = 'asc';
    return array.sort((a: any, b: any) => {
      const aStr = a[sortBy].toString();
      const bStr = b[sortBy].toString();
      return aStr.localeCompare(bStr);
    });
  }
}