<div id="wrapper" class="bg-light">
  <div id="nav" class="bg-alofoq py-3 text-light">
    <div class="container">
      <div class="row">
        <div class="col col-sm text-sm-start text-center">
          <div class="h-100 w-100 d-flex align-items-center">
            <div class="row">
              <div class="col-12">
                <a href="#" [routerLink]="'/admin'" class="text-light">
                  <b>الأفق الرقمي لخدمات الإنترنت</b>
                </a>
              </div>
              <div class="col-12">
                <a href="#" [routerLink]="'/admin'" class="text-light">                
                  <p class="mb-0">نظام إدارة عقود المشتركين</p>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class=" col-sm-4">
         <div class="h-100 w-100 d-flex align-items-center justify-content-sm-end justify-content-center">  
          <!--   <button class="btn btn-outline-light rounded-0 btn-sm" title="تسجيل الخروج" (click)="logout()">
              <small>
                <small><i class="fa fa-power-off pe-2"></i></small>
                خروج  
                <ng-container *ngIf="authDetails">
                       {{authDetails['client']['username']}}
                </ng-container>
              </small>
            </button>    
            <a *ngIf="checkPermission('prm_managers_login_as')" class="btn btn-outline-light rounded-0 btn-sm" title="تسجيل الخروج" href="#/admin/LoginAs">
              
              <ng-container >
                  <ng-container >
                      <i class="fa fa-user-secret pe-2"></i>
                      الدخول بـ....
                    </ng-container>
              </ng-container>
            </a> -->
            <div class="dropdown">
              <button
                class="btn btn-outline-light dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-bs-toggle="dropdown"
                aria-expanded="false">
                <i class="fas fa-user-circle pe-2"></i>
                <small> 
                  <ng-container *ngIf="authDetails">
                  {{authDetails['client']['username']}}
           </ng-container></small>
   </button>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <li>
                  <a *ngIf="checkPermission('prm_managers_login_as')" class="dropdown-item" title="تسجيل الخروج" href="#/admin/LoginAs">
                    <i class="fa fa-user-secret pe-2"></i> الدخول بـ....
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" href="#/admin/changePassword">
                    <i class="fas fa-key pe-2"></i> تغيير كلمة السر
                  </a>
                </li>
                <li>
                  <button class="dropdown-item" title="تسجيل الخروج" (click)="logout()">
                    <i class="fa fas fa-sign-out-alt pe-2"></i> تسجيل الخروج
                  </button>    
                </li>
              </ul>
            </div>

          </div>
          
        </div>

      </div>
    </div>
  </div>
    <router-outlet></router-outlet>
</div>
