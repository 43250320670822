import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'secondsToAgo'
})
export class SecondsToAgoPipe implements PipeTransform {

  transform(value: number): string {
    const seconds = Math.floor((value));

    if (seconds < 60) {
      return 'منذ لحظات';

    }

    const minutes = Math.floor(seconds / 60);

    if (minutes == 1) 
      {
        return ' منذ دقيقة واحد' ;
      }
      else if (minutes == 2) 
      {
        return ' منذ دقيقتين' ;
      }
      else if (minutes < 11) {
        return ' منذ' + ' ' + minutes + ' دقائق' ;
  
      }
      else if (minutes < 60) {
        return ' منذ' + ' ' + minutes + ' دقيقة' ;
  
      }
  

    const hours = Math.floor(minutes / 60);
    if (hours == 1) 
    {
      return ' منذ ساعة واحد' ;
    }
    else if (hours == 2) 
    {
      return ' منذ ساعتين' ;
    }
    else if (hours < 11) {
      return ' منذ' + ' ' + hours + ' ساعات' ;

    }
    else if (hours < 24) {
      return ' منذ' + ' ' + hours + ' ساعة' ;

    }

    const days = Math.floor(hours / 24);
     if (days == 1) 
    {
      return ' منذ يوم واحد' ;
    }
    else if (days == 2) 
    {
      return ' منذ يومين' ;
    }
    else if (days < 11) {
      return ' منذ' + ' ' + days + ' أيام' ;

    }
    else if (days < 3000) {
      return ' منذ' + ' ' + days + ' يوماً' ;

    }

  }
}

