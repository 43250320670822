import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'menuFilter'
})
export class MenuFilterPipe implements PipeTransform {

  transform(input: string[], length: number) {
    return input.filter(option => option.length >= length);
  }

}