import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { SnackService } from 'src/app/services/snack.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  user = {
    username: null,
    password: null,
  };

  constructor(private router: Router, public snack: SnackService, public auth: AuthService) { }

  ngOnInit(): void {
  }

  
  login(form): void {
    if (form.valid) {
      let requestData = {"username":form.value.username,"password":form.value.password,"language":"ar"};      
      this.auth.operations.login(requestData).subscribe(
        (res) => {
          localStorage.setItem('JWT_TOKEN', res['token']);
          this.router.navigateByUrl('/admin/contract');
        },
        (error) => {
          if (error.errorStatus == 403) {
            this.snack.showError("الرجاء التحقق من إدخال اسم المستخدم وكلمة المرور بشكل صحيح.");
          }
          else {
            this.snack.showError('There is something wrong. Please check the console log.');
            console.log(error);
          }
        });
    }
    else {
      this.snack.showError('الرجاء التأكد من تعبئة البيانات بشكل صحيح.');
    }
  }
}
