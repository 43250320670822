    <!-- data-bs-backdrop="static" -->
    <div  data-bs-backdrop="static" data-bs-keyboard="false"  tabindex="-1" aria-labelledby="changePackageModalLabel" aria-hidden="true">
        <div>
        <div >
        <div class="modal-header border-bottom ">
           <h4 class="modal-title  font-weight-bold" id="changePackageModalLabel">لتسجيل الدخول يرجى تغيير كلمة سر</h4>
        </div>
        <div class="modal-body pt-0">
        <div class="">
        <ng-container >
        <ng-container >
        <div class="container">
           <div class="row h-100 mt-4">
              <form #changepasswordform="ngForm" (ngSubmit)="onSubmit(changepasswordform)">
              <div class="card-body">
                <div class="col-md-4 col-xl-4">
                    <label class="w-100">
                        كلمة المرور
                        <span class="text-danger">*</span>
                        <div class="input-group mt-2">
                            <input   
                                class="form-control"
                                [(ngModel)]="ChangePasswordData.password"
                                name="password"
                                #password="ngModel" >
                        </div>
                    </label>
                </div>
                <div class="col-md-4 col-xl-4">
                    <label class="w-100">
                        تأكيد كلمة المرور
                        <span class="text-danger">*</span>
                        <div class="input-group mt-2">
                            <input 
                                class="form-control"
                                [(ngModel)]="ChangePasswordData.confirm_password"
                                name="confirm_password"
                                #confirm_password="ngModel" >
                        </div>
                    </label>
                    <small class="text-danger"
                        *ngIf="confirm_password.touched && confirm_password.value != password.value || changepasswordform.submitted && confirm_password.value != password.value">
                        * يجب ان تكون الكلمتين متطابقتين
                    </small>
                </div>

            </div>
              </form>
              <div class="card-body">
                 <form #changepasswordform="ngForm" (ngSubmit)="onSubmit(changepasswordform)">    
                 <div class=" text-center text-sm-end">
                    <hr>
                    <button type="submit" class="btn btn-sm btn-alofoq"
                       >
                       <ng-container >
                          <ng-container >
                             <i class="fa fa-user-secret pe-2"></i>
                             تغيير كلمة السر
                          </ng-container>
                       </ng-container>
                    </button>
                 </div>
                 </form>
              </div>
           </div>
           <ng-container >
              <div class="row mt-4">
                 <div class="col-12">
                 </div>
              </div>
           </ng-container>
        </div>
        