import { PrintContractComponent } from './print/print-contract/print-contract.component';
import { PrintPdfComponent } from './print/print-pdf/print-pdf.component';
import { SpecificContractComponent } from './pages/specific-contract/specific-contract.component';
import { ContractComponent } from './pages/contract/contract.component';
import { DashboardLayoutComponent } from './layouts/dashboard-layout/dashboard-layout.component';
import { LoginComponent } from './pages/login/login.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContractStatusPipe } from './pipes/contractStatus.pipe';
import { LoginAsComponent } from './pages/login-as/login-as.component';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';


const DashboardLayoutClidren: Routes = [
  {path: '', component: ContractComponent},
  {path: 'contract', component: ContractComponent},
  {path: 'contract/add', component: SpecificContractComponent},
  {path: 'contract/:id', component: SpecificContractComponent},
  {path: 'LoginAs', component: LoginAsComponent},
  {path: 'changePassword', component: ChangePasswordComponent},
];

const routes: Routes = [
  {path: '', component: LoginComponent},
  {path: 'admin/print-contract/:id', component: PrintContractComponent},
  {path: 'admin/print-pdf/:id', component: PrintPdfComponent},
  {path: 'admin', component: DashboardLayoutComponent, children: DashboardLayoutClidren}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
