import { ApiService } from './../../services/api-service.service';
import { SnackService } from './../../services/snack.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContractModel } from 'src/app/models/contract';
import { forkJoin } from 'rxjs';
import { ContractOverviewModel } from 'src/app/models/contract-overview';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-print-pdf',
  templateUrl: './print-pdf.component.html',
  styleUrls: ['./print-pdf.component.scss']
})
export class PrintPdfComponent implements OnInit {

  contractDetails: ContractModel
  contractOverview: ContractOverviewModel

  isLoading: boolean
  
  constructor(private snack: SnackService, private api: ApiService, private activatedRoute: ActivatedRoute, private router: Router, private titleService: Title) { 

    let id = this.activatedRoute.snapshot.paramMap.get('id');
    let isNum = (value) => /^\d+$/.test(value);
    if (isNum(id)) {
      this.getContract(id);
    }
    else {
      this.router.navigateByUrl("/admin");
      this.snack.showError("The id is not valid!");
    }
  }

  ngOnInit(): void {
  }


  getContract(id) {
    this.isLoading = true
    const contractInformationRequest = this.api.get('user/' + id)
    const contractOverviewRequest = this.api.get('user/overview/' + id)
    forkJoin([
      contractInformationRequest,
      contractOverviewRequest,
    ]).subscribe(
    res => {
      this.contractDetails = res[0]['data']
      this.contractOverview= res[1]['data']
      this.titleService.setTitle(`Alofoq Contract System - ${this.contractDetails.contract_id} - ${this.contractDetails.username}`);
      this.isLoading = false        
      setTimeout(() => {print();},1000);
    }, err => {
      this.router.navigateByUrl("/admin")
      this.snack.showError('There is something wrong. Please check the console!')
      this.isLoading = false
      console.log(err)
    });
  }

  changeSubtype(value) {
    this.contractDetails.sub_type = value;
  }

  getDisplayValue() {
    switch (this.contractDetails.sub_type) {
      case 1:
        return 'اشتراك منازل - جديد';
      case 2:
        return 'اشتراك منازل - نقل';
      case 3:
        return 'اشتراك شركات - خط مشترك';
      case 4:
        return 'اشتراك شركات - خط مخصص';
      default:
        return 'غير محدد';
    }
  }
}
