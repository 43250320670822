<div class="container pt-5">
    <div class="row mt-3 pb-5">
       <div class="col-lg-12">
          <h5 class="font-weight-bold"><i class="fa fa-users pe-2"></i> عقود المشتركين</h5>
          <hr />
          <div class="card hover-shadow">
             <div class="card-body p-0">
                <div class="text-center" *ngIf="!pagedContracts">
                   <div class="spinner-border spinner-border-sm" role="status"></div>
                </div>
                <div class="px-4 pt-3 pb-4 border border-bottom-0">
                   <div class="row">
                      <div class="col-xl-4 col-6 col-sm-4 my-2">
                         <a routerLink="/admin/contract/add" class="btn lg-2 btn-outline-danger hover-shadow rounded-pill w-100 font-weight-bold">إضافة عقد جديد</a>
                      </div>
                      <div class="col-xl-4 col-6 col-sm-4 my-2">
                         <div class="btn-group w-100">
                            <button
                               class="btn lg-2 btn-outline-danger hover-shadow rounded-pill dropdown-toggle w-100 font-weight-bold"
                               type="button"
                               id="defaultDropdown"
                               data-bs-toggle="dropdown"
                               data-bs-auto-close="true"
                               aria-expanded="false"
                               >
                            تعديل الأعمدة
                            </button>
                            <ul class="dropdown-menu dropdown-menu-end w-100 p-3 hover-shadow shadow-sm" style="max-height: 250px; overflow-y: auto;">
                               <li class="py-2" *ngFor="let col of allColumns">
                                  <input [class.visually-hidden]="col.prop == 0" type="checkbox" [id]="col.name" (click)="toggle(col)" [checked]="isChecked(col)" />
                                  <label [ngClass]="col.prop == 0?'btn btn-outline-primary btn-sm w-100':''" class="ps-2" [attr.for]="col.name">{{ col.name }}</label>
                               </li>
                            </ul>
                         </div>
                      </div>
                      <!-- <div class="col-xl-4 col-6 col-sm-4 my-2">
                         <button class="button" class="btn lg-2 btn-outline-danger hover-shadow rounded-pill w-100 font-weight-bold"
                             [disabled]="isLoading"
                             (click)="resetFilter(); getContracts({offset: 0}, {sort_by: 'id', direction: 'desc'})">تحديث
                             البيانات</button>
                         </div>   !-->
                      <div class="col-xl-4 col-6 col-sm-4 my-2">
                         <button type="button" (click)="toggleSmartFiltering()" class="btn lg-2 btn-outline-danger hover-shadow rounded-pill w-100 font-weight-bold">
                         البحث المتقدم
                         </button>
                      </div>
                   </div>
                   <div class="row">
                    <div class="col-12">
                        <form #smartFilteringForm="ngForm" (ngSubmit)="smartFiltering(smartFilteringForm.value)">
                         <div class="mt-2">
                            <div class="row gy-12">
                               <div class="col-md-4 col-xl-7">
                                  <label class="w-100">
                                     <div class="input-group mt-2">
                                        <input   
                                           class="form-control"
                                           type="text" name="filterSearchText" 
                                           appAutofocus placeholder="بحث..." 
                                           #filterSearchText="ngModel" 
                                           ngModel />
                                        <span class="input-group-text p-0 bg-white">
                                        <button 
                                           type="button"
                                           (click)="smartFiltering(smartFilteringForm.value)"
                                           class="btn btn-sm">
                                        <i class="fa mt-1 px-1 fa-search"></i>
                                        </button>
                                        </span>
                                     </div>
                                  </label>
                               </div>
                            </div>
                         </div>
                         <div class="mt-2">
                            <div class="row gy-12">
                               <div class="col-md-4 col-xl-7">
                                  <label class="w-100">
                                  </label>
                               </div>
                            </div>
                         </div>
                   <div class="row my-3" *ngIf="showSmartFiltering">
                      <div class="col-12">
                         <h6 class="font-weight-bold mb-0">البحث المتقدم</h6>
                         <hr class="mt-2" />
                      </div>
                      <div class="col-12">
                         <div class="row">
                            <div class="col-6 col-sm-4 col-lg-3 my-3">
                               <label class="w-100">
                               MAC
                               <input class="form-control mt-2" type="text" name="filterMac" #filterMac="ngModel" ngModel />
                               </label>
                            </div>
                            <div class="col-6 col-sm-4 col-lg-3 my-3">
                               <label class="w-100">
                                  الحالة
                                  <select class="form-select mt-2" type="text" name="filterStatus" #filterStatus="ngModel" ngModel>
                                     <option value="-1">الكل</option>
                                     <option value="1">فعال</option>
                                     <option value="2">منتهي الصلاحية</option>
                                     <option value="3">موقوف</option>
                                     <option value="4">ينتهي قريبًا</option>
                                     <option value="5">ينتهي اليوم</option>
                                  </select>
                               </label>
                            </div>
                            <div class="col-6 col-sm-4 col-lg-3 my-3">
                               <label class="w-100">
                                  الاتصال
                                  <select class="form-select mt-2" type="text" name="filterConnection" #filterConnection="ngModel" ngModel>
                                     <option value="-1" [selected]="true">الكل</option>
                                     <option value="1">غير متصل</option>
                                     <option value="2">متصل</option>
                                  </select>
                               </label>
                            </div>
                            <div class="col-6 col-sm-4 col-lg-3 my-3">
                               <label class="w-100">
                                  الباقة
                                  <select class="form-select mt-2" type="text" name="filterProfile" #filterProfile="ngModel" ngModel>
                                     <option value="-1" [selected]="true">الكل</option>
                                     <option *ngFor="let item of profiles" [value]="item.id"> {{item.name}}</option>
                                  </select>
                               </label>
                            </div>
                            <div class="col-6 col-sm-4 col-lg-3 my-3">
                               <label class="w-100">
                                  تابع إلى
                                  <select class="form-select mt-2" type="text" name="filterParentId" #filterParentId="ngModel" ngModel>
                                     <option value="-1" [selected]="true">الكل</option>
                                     <option *ngFor="let item of parents | menuSorting: 'username'" [value]="item.id"> {{item.username}}</option>
                                  </select>
                               </label>
                            </div>
                            <div class="col-6 col-sm-4 col-lg-3 my-3">
                               <label class="w-100">
                                  المشتركين الفرعيين
                                  <select class="form-select mt-2" type="text" name="filterSubUsers" #filterSubUsers="ngModel" ngModel>
                                     <option value="true">نعم</option>
                                     <option value="false">لا</option>
                                  </select>
                               </label>
                            </div>
                         </div>
                         <div class="col-12 mt-3 mb-4">
                            <button class="btn btn-alofoq px-4">البحث</button>
                         </div>
                         <hr />
                      </div>
                   </div>
                </form>
                </div>
                <div *ngIf="pagedContracts">
                   <ngx-datatable
                   class="material striped shadow-sm border"
                   [rows]="pagedContracts.data"
                   [loadingIndicator]="isLoading"
                   [columns]="columns"
                   [columnMode]="ColumnMode.force"
                   [headerHeight]="40"
                   [footerHeight]="50"
                   [scrollbarH]="true"
                   [scrollbarV]="false"
                   [externalPaging]="true"
                   [externalSorting]="true"
                   [count]="page?.totalElements"
                   [offset]="page?.pageNumber"
                   [limit]="page?.size"
                   (page)="getContracts($event, {sort_by: 'id', direction: 'desc'})"
                   [selectionType]="SelectionType.single"
                   (select)="onSelect($event)"
                   (sort)="onSort($event)"
                   [virtualization]="false"
                   [messages]="{'emptyMessage': 'لا توجد بيانات لعرضها','totalMessage': ':الإجمالي '}"
                   rowHeight="auto"
                   >
                   <ngx-datatable-column *ngFor="let col of columns" [name]="col.name" [prop]="col.prop" [sortable]="!col.sortable ? false : true">
                   <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                      <span [class.font-weight-bold]="row.online_status == 1" [class.text-danger]="row.enabled == 0">
                      <ng-container [ngSwitch]="col.prop">
                         <ng-container *ngSwitchCase="'status'">
                            {{(value | contractStatus).status}}
                         </ng-container>
                         <ng-container *ngSwitchCase="'daily_traffic_details.traffic'">
                            <span dir="ltr">{{value | formatBytes}}</span>
                         </ng-container>
                         <ng-container *ngSwitchCase="'available_traffic'">
                            <span dir="ltr">{{value | formatBytes}}</span>
                         </ng-container>
                         <ng-container *ngSwitchCase="'used_traffic'">
                            <div class="progress position-relative border" [title]="value + '%'">
                               <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuemin="0" aria-valuemax="100" [style.width]="value + '%'" [class.bg-warning]="value < 30">
                               <div [ngClass]="value < 30 ? 'position-absolute w-100' : ''">
                                  <small class="font-weight-bold">{{value}}%</small>
                               </div>
                            </div>
                </div>
                </ng-container>
                <ng-container *ngSwitchDefault>
                {{value}}
                </ng-container>
                </ng-container>
                </span>
                </ng-template>
                </ngx-datatable-column>
                </ngx-datatable>
             </div>
          </div>
       </div>
    </div>
 </div>
 <div class="row">
    <div class="col-12">
       <h5 class="font-weight-bold"><i class="fa fa-tv pe-2"></i> إحصائيات النظام</h5>
       <hr />
    </div>
    <!-- <div class="col-12 col-xl-3 col-md-6">
       <div class="card shadow-sm hover-shadow my-3">
           <div class="card-body">
               <div class="float-right">
                   <i class="fa fa-user-circle text-alofoq h4 ml-3"></i>
               </div>
               <p class="text-muted mb-0">إجمالي المشتركين</p>
               <div class="mt-2 pt-1 font-size-20">
                   <h5 class="font-weight-bold">{{usersCount}}</h5>
                   <div class="spinner-border spinner-border-sm" role="status" *ngIf="usersCount == undefined">
                   </div>
               </div>
           </div>
       </div>
       </div> -->
    <!-- <div class="col-6 col-xl-3 col-md-6">
       <div class="card shadow-sm hover-shadow my-3">
           <div class="card-body">
               <div class="float-right">
                   <i class="fa fa-smile text-alofoq h4 ml-3"></i>
               </div>
               <p class="text-muted mb-0">المشتركين الفعالين</p>
               <div class="mt-2 pt-1 font-size-20">
                   <h5 class="font-weight-bold">{{activeUsers}}</h5>
                   <div class="spinner-border spinner-border-sm" role="status" *ngIf="activeUsers == undefined">
                   </div>
               </div>
           </div>
       </div>
       </div> -->
    <!-- <div class="col-6 col-xl-3 col-md-6">
       <div class="card shadow-sm hover-shadow my-3">
           <div class="card-body">
               <div class="float-right">
                   <i class="fa fa-lightbulb text-alofoq h4 ml-3"></i>
               </div>
               <p class="text-muted mb-0">المشتركين المتصلين</p>
               <div class="mt-2 pt-1 font-size-20">
                   <h5 class="font-weight-bold">{{onlineUsers}}</h5>
                   <div class="spinner-border spinner-border-sm" role="status" *ngIf="onlineUsers == undefined">
                   </div>
               </div>
           </div>
       </div>
       </div> -->
    <!-- <div class="col-6 col-xl-3 col-md-6">
       <div class="card shadow-sm hover-shadow my-3">
           <div class="card-body">
               <div class="float-right">
                   <i class="fa fa-frown text-alofoq h4 ml-3"></i>
               </div>
               <p class="text-muted mb-0">المنتهي اشتراكهم</p>
               <div class="mt-2 pt-1 font-size-20">
                   <h5 class="font-weight-bold">{{expiredUsers}}</h5>
                   <div class="spinner-border spinner-border-sm" role="status" *ngIf="expiredUsers == undefined">
                   </div>
               </div>
           </div>
       </div>
       </div> -->
    <!--  <div class="col-6 col-xl-3 col-md-6">
       <div class="card shadow-sm hover-shadow my-3">
           <div class="card-body">
               <div class="float-right">
                   <i class="fa fa-calendar-alt text-alofoq h4 ml-3"></i>
               </div>
               <p class="text-muted mb-0">ينتهي في 3 ايام</p>
               <div class="mt-2 pt-1 font-size-20">
                   <h5 class="font-weight-bold">{{expiredSoonUsers}}</h5>
                   <div class="spinner-border spinner-border-sm" role="status"
                       *ngIf="expiredSoonUsers == undefined"></div>
               </div>
           </div>
       </div>
       </div>
       <div class="col-6 col-xl-3 col-md-6">
       <div class="card shadow-sm hover-shadow my-3">
           <div class="card-body">
               <div class="float-right">
                   <i class="fa fa-gift text-alofoq h4 ml-3"></i>
               </div>
               <p class="text-muted mb-0">النقاط التشجيعية</p>
               <div class="mt-2 pt-1 font-size-20">
                   <h5 class="font-weight-bold">{{rewardPoints}}</h5>
                   <div class="spinner-border spinner-border-sm" role="status" *ngIf="rewardPoints == undefined">
                   </div>
               </div>
           </div>
       </div>
       </div> -->
    <div class="col-6 col-xl-3 col-md-12">
       <div class="card shadow-sm hover-shadow my-3">
          <div class="card-body">
             <div class="float-right">
                <i class="fa fa-money-bill-alt text-alofoq h4 ml-3"></i>
             </div>
             <p class="text-muted mb-0">الرصيد</p>
             <div class="mt-2 pt-1 font-size-20">
                <h5 class="font-weight-bold"><span dir="ltr">{{totalBalance}}</span></h5>
                <div class="spinner-border spinner-border-sm" role="status" *ngIf="totalBalance == undefined"></div>
             </div>
          </div>
       </div>
    </div>
    <div class="col-6 col-xl-3 col-md-12">
       <div class="card shadow-sm hover-shadow my-3">
          <div class="card-body">
             <div class="float-right">
                <i class="fa fa-arrow-circle-up text-alofoq h4 ml-3"></i>
             </div>
             <p class="text-muted mb-0">الديون المستحقة عليك</p>
             <div class="mt-2 pt-1 font-size-20">
                <h5 class="font-weight-bold"><span dir="ltr">{{wdOutstandingDebts}}</span></h5>
                <div class="spinner-border spinner-border-sm" role="status" *ngIf="wdOutstandingDebts == undefined"></div>
             </div>
          </div>
       </div>
    </div>
    <div class="col-6 col-xl-3 col-md-12">
       <div class="card shadow-sm hover-shadow my-3">
          <div class="card-body">
             <div class="float-right">
                <i class="fa fa-arrow-circle-down text-alofoq h4 ml-3"></i>
             </div>
             <p class="text-muted mb-0">الديون المستحقة لك</p>
             <div class="mt-2 pt-1 font-size-20">
                <h5 class="font-weight-bold"><span dir="ltr">{{wdOutstandingClaims}}</span></h5>
                <div class="spinner-border spinner-border-sm" role="status" *ngIf="wdOutstandingClaims == undefined"></div>
             </div>
          </div>
       </div>
    </div>
 </div>
 </div>
 