import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatBytes'
})
export class FormatBytesPipe implements PipeTransform {

  transform(value, ...args: unknown[]): unknown {
      let digits = 3

      if(0 == value) {
        return "0 Bytes"
      }

      const x = 0 > digits ? 0 : digits,d=Math.floor(Math.log(value)/Math.log(1024));

      let result = parseFloat((value/Math.pow(1024,d)).toFixed(x))+" "+["Bytes","KB","MB","GB","TB","PB","EB","ZB","YB"][d] 

    return result;
  }

}

@Pipe({
  name: 'timeformat'
})
export class timeformatPipe implements PipeTransform {

  transform(value, ...args: unknown[]): unknown {
      let digits = 3

      if(0 == value) {
        return "0 Bytes"
      }

      const x = 0 > digits ? 0 : digits,d=Math.floor(Math.log(value)/Math.log(1024));

      let result = parseFloat((value/Math.pow(1024,d)).toFixed(x))+" "+["Bytes","hhhB","MB","GB","TB","PB","EB","ZB","YB"][d] 

    return result;
  }

}
