import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChange, ViewChild } from '@angular/core';
import { ContractOverviewModel } from 'src/app/models/contract-overview';
import { SnackService } from 'src/app/services/snack.service';
import { ContractModel } from 'src/app/models/contract';
import { ApiService } from 'src/app/services/api-service.service';
import { NgForm } from '@angular/forms';
import { formatDate } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-login-as',
  templateUrl: './login-as.component.html',
  styleUrls: ['./login-as.component.scss']
})
export class LoginAsComponent implements OnInit {
  
  isLoading: boolean = false
  contractOverview: ContractOverviewModel
  isEdit: boolean
  isPasswordHidden: boolean = true
  parents: []
  auth: {id: any}
  LoginAs: { manager_id: any; };
  searchTerm: string;
  loggedInManagerId: number;


  constructor(private api: ApiService, private snack: SnackService, private activatedRoute: ActivatedRoute, private router: Router, private titleService: Title) {
    let id = this.activatedRoute.snapshot.paramMap.get('id');
    let isNum = (value) => /^\d+$/.test(value);
    if (id) {
      if (isNum(id)) {
        this.isEdit = true
      }
      else {
        this.router.navigateByUrl("/admin");
        this.snack.showError("The id is not valid!");
      }
    } else {
      this.isEdit = false
    }
  }




  ngOnInit(): void {
    this.getAuth()
    this.getParents()
    this.setInitialValues()
    this.chackpasswordforce();

  }

  setInitialValues(): void {
    this.LoginAs = {
      manager_id:null,


    }
    
  }

  getParents(): void {
    this.isLoading = true;
    this.api.get('index/manager').subscribe(
      res => {
        // Exclude the logged-in manager
        this.parents = res['data'].filter(item => item.id !== this.loggedInManagerId);
        this.isLoading = false;
      },
      err => {
        this.snack.showError('There is something wrong. Please check the console!');
        this.isLoading = false;
        console.log(err);
      }
    )
  }
  

  onSubmit(form: NgForm): void {
    if(form.valid) {

      
      {
        this.LoginAsmanger(this.LoginAs.manager_id)
      } 
    } else {
      this.snack.showError('الرجاء التحقق من إدخال كافة البيانات بشكل صحيح.')
    }      
  }



  LoginAsmanger(id) {

    this.api.post('manager/loginAs', this.LoginAs, true).subscribe
    (
      (res) => {
        localStorage.setItem('JWT_TOKEN', res['token']);
        this.router.navigateByUrl('/admin/contract');
        this.isLoading = true
        this.router.navigate(['/admin'])
        .then(() => {
          window.location.reload();
        });      },
 err => {
      this.snack.showError('There is something wrong. Please check the console!')
      this.isLoading = false
      console.log(err);
    })

  }




  getAuth(): void {
    this.isLoading = true;
    this.api.get('auth').subscribe(
      res => {
        this.auth = res;
        this.loggedInManagerId = res.client.id; // Save the logged-in manager's ID
        if(!this.isEdit) {
          if(!this.checkPermission('prm_users_create')) {
            this.router.navigateByUrl("/admin");
          }
        }
        this.isLoading = false;
      },
      err => {
        this.snack.showError('There is something wrong. Please check the console!');
        this.isLoading = false;
        console.log(err);
      }
    )
  }
  
  checkPermission(permissionType: string) {    
    console.log(permissionType, " => ", this.auth['permissions'].includes(permissionType));
    return this.auth['permissions'].includes(permissionType);
  }
  chackpasswordforce(): void {
    this.isLoading = true
    this.api.get('auth').subscribe(
      res => {
        this.auth = res
        if (res.client.force_change_password === 1) {
          this.router.navigate(['admin/changePassword'])
          this.snack.showError('يجب تغيير كلمة السر للمتابعة!')

        }

        this.isLoading = false
      },
      err => {
        this.snack.showError('There is something wrong. Please check the console!')
        this.isLoading = false
        console.log(err);
      }
    )
  }

}

