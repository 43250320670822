import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isTower'
})
export class IncludePipe implements PipeTransform {

  transform(value: string): unknown {
    let item = value.toLowerCase()
    let result = false
    if(item.includes('tower')) {
      result =  true
    } else {
      result = false
    }
    return result;
  }

}
