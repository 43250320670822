import { ContractStatusPipe } from 'src/app/pipes/contractStatus.pipe';
import { ContractComponent } from './pages/contract/contract.component';
import { DashboardLayoutComponent } from './layouts/dashboard-layout/dashboard-layout.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorInterceptorService } from './services/error-interceptor.service';
import { SpecificContractComponent } from './pages/specific-contract/specific-contract.component';
import { PrintContractComponent } from './print/print-contract/print-contract.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AutofocusDirective } from './directives/autofocus.directive';
import { FormatBytesPipe } from './pipes/format-bytes.pipe';
import { IncludePipe } from './pipes/is-tower.pipe';
import { SecondsToAgoPipe } from './pipes/seconds-to-ago.pipe';
import { RenewModalComponent } from './components/modals/renew-modal/renew-modal.component';
import { RedeemModalComponent } from './components/modals/redeem-modal/redeem-modal.component';
import { ChangePackageModalComponent } from './components/modals/change-package-modal/change-package-modal.component';
import { LoginAsComponent } from './pages/login-as/login-as.component';
import { MenuSortingPipe } from './pipes/menu-sorting.pipe';
import { MenuFilterPipe } from './pipes/menu-filter.pipe';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { PrintPdfComponent } from './print/print-pdf/print-pdf.component';


@NgModule({
  declarations: [
    RenewModalComponent,
    RedeemModalComponent,
    ChangePackageModalComponent,
    AppComponent,
    LoginComponent,
    DashboardLayoutComponent,
    ContractComponent,
    SpecificContractComponent,
    PrintContractComponent,
    AutofocusDirective,
    ContractStatusPipe,
    FormatBytesPipe,
    IncludePipe,
    SecondsToAgoPipe,
    LoginAsComponent,
    MenuSortingPipe,
    MenuFilterPipe,
    ChangePasswordComponent,
    PrintPdfComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    RouterModule,
    FormsModule,
    HttpClientModule,
    NgxDatatableModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    ContractStatusPipe,
    FormatBytesPipe,
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptorService,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
