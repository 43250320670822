<div class="bg-animated">
    <div class="login-wrapper fadeIn">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 mx-auto text-center">
                    <form #loginForm="ngForm" (ngSubmit)="login(loginForm)">
                        <div class="card hover-shadow shadow-sm" style="border-radius: 10px;">
                            <div class="card-body py-5">
                                <div class="mb-4">
                                    <img src="/assets/img/alofoq_logo_main.png" class="img-fluid" alt="Alofoq Logo" />
                                    <small>نظام إدارة عقود المشتركين</small>
                                </div>
                                <div class="px-3 mt-2">
                                    <div class="form-control-group mb-4">
                                        <input 
                                            class="fadeIn rounded-pill form-control hover-shadow"
                                            placeholder="اسم المستخدم"
                                            [(ngModel)]="user.username"
                                            #username="ngModel"
                                            name="username"
                                            type="text"
                                            required
                                            appAutofocus
                                        >
                                        <small *ngIf="
                                        (username.errors?username.errors['required']:false)
                                        && username.touched" class="text-danger" style="font-family: tahoma">
                                        الرجاء إدخال اسم المستخدم
                                        </small>
                                    </div>
    
                                    <div class="form-control-group mb-4">
                                        <input
                                            type="password"
                                            class="fadeIn rounded-pill form-control hover-shadow"
                                            placeholder="كلمة المرور"
                                            [(ngModel)]="user.password"
                                            #password="ngModel"
                                            name="password"
                                            type="password"
                                            required
                                        >
                                        <small *ngIf="
                                        (password.errors?password.errors['required']:false)
                                        && password.touched" class="text-danger" style="font-family: tahoma">
                                        الرجاء إدخال كلمة المرور!
                                        </small>                                    
                                    </div>
                                    <input 
                                        type="submit"
                                        class="fadeIn w-100 rounded-pill btn btn-alofoq font-weight-bold hover-shadow"
                                        value="تسجيل الدخول"
                                    >
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>