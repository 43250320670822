import { SnackService } from './snack.service';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from './auth.service';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ErrorInterceptorService implements HttpInterceptor {

  constructor(public authService: AuthService, public router: Router, private snack: SnackService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (this.authService.getJwtToken()) {
      request = this.addToken(request, this.authService.getJwtToken());
    }

    return next.handle(request)
      .pipe(
        retry(1),
        catchError((error: HttpErrorResponse) => {
          switch (error.status) {
            case 0:
              console.log('Connection Issues');
              break;
            case 301:
              console.log('The page you requested is moved permenantly');
              break;
            case 400:
              console.log('Bad Request');
              break;
            case 401:
              console.log('Email or password incorrect');
              if(error.error.message) {
                this.snack.showError(error.error.message);
                if(error.error.message == 'Token not provided') {
                  this.authService.operations.logout();                  
                }
                
              } else {
                this.snack.showError('Please login again.');
              }
              this.authService.operations.logout();
              break;           
            case 403:
              console.log('This page is forbidden');
              break;
            case 404:
              console.log('Resource not found');
              break;
            case 500:
              console.log('Internal Server Error');
              break;
            default:
              if (error.status >= 300 && error.status <= 399) {
                console.log('Redirection error');
              } else if (error.status >= 405 && error.status <= 499) {
                console.log('Client-side error');
              } else if (error.status >= 500 && error.status <= 599) {
                console.log('Server-side error');
              } else {
                console.log('Undefined Error');
              }
              break;
          }
          return throwError({ errorStatus: error.status, error: error.error });
        })
      );
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }
  
}
