import { CryptoService } from './crypto.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment.prod';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class ApiService {
  api: string = environment.API_URL

  constructor(private http: HttpClient, private crypto: CryptoService) {}

  public get(route, options?: any){    
    return this.http.get(`${this.api}${route}`, options).pipe(map((response:any) => {
      return response;
    }));
  }

  public post(route, data, encrypted?: boolean){
    data = encrypted ? this.crypto.encryptData(data) : data
    return this.http.post(`${this.api}${route}`, data).pipe(map((response:any) => {
      return response;
    }));
  }

  public patch(route, data, encrypted?: boolean){
    data = encrypted ? this.crypto.encryptData(data) : data
    return this.http.patch(`${this.api}${route}`, data).pipe(map((response:any) => {
      return response;
    }));
  }

  public put(route, data, encrypted?: boolean){
    data = encrypted ? this.crypto.encryptData(data) : data
    return this.http.put(`${this.api}${route}`, data).pipe(map((response:any) => {
      return response;
    }));
  }

  public delete(route){
    return this.http.delete(`${this.api}${route}`).pipe(map((response:any) => {
      return response;
    }));
  }

}