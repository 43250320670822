<div class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" [id]="modalName" tabindex="-1" aria-labelledby="renewModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
       <div #modalContent class="modal-content  glassmorphism-card rounded-lg px-2 px-lg-5 py-3" style="overflow-y: auto;">
          <div class="modal-header  border-bottom-0 pb-0">
            <button title="تحديث بيانات المشترك" (click)="refreshdata(contractDetails.id)"
            class="btn btn-sm btn-alofoq me-2">
               <i class="fa fa-sync "></i>
            </button>

             <i class="fa fa-money-bill-alt pe-2"></i>
             إيداع الى العقد 
             ( {{contractDetails.username}} , {{contractDetails.firstname}} {{contractDetails.lastname}} )
             <button (click)="changeStatusToClosed(modalName)" #closeButton type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" title="إغلاق"></button>
          </div>
          <hr>
          <div  *ngIf="(isEdit && !isLoading) || (!isEdit)">
             <div class="row">
                <ng-container>
                   <div class="col-12 col-xl">
                      <div >
                         <div class="card-body">
                            <div class="row text-center text-xl-start">
                               <div class="col-md-4 col-lg-3  col-6 ">
                                  <small class="d-block">
                                  <b>رصيد المدير</b>
                                  </small>
                                  <div >
                                     <ul class="list-unstyled">
                                        <li >
                                          {{ActivateData.manager_balance || 0 }}
                                          <!--  {{authDetails['client']['balance'] | number}}-->
                                        </li>
                                     </ul>
                                  </div>
                               </div>
                               <div class="col-md-4 col-lg-3  col-6 "  >
                                  <small class="d-block">
                                  <b>رصيد المشترك</b>
                                  </small>
                                  <div >
                                     <ul class="list-unstyled">
                                        <li >
                                           {{ActivateData.user_balance || 0 }}
                                        </li>
                                     </ul>
                                  </div>
                               </div>
                            </div>
                         </div>
                         <form #depositeform="ngForm" (ngSubmit)="onFormSubmit(depositeform)">
                         <div class="card-body">
                            <hr>
                            <div class="row gy-4">
                               <div class="col-lg-4">
                                  <label class="w-100">
                                  المبلغ
                                  <span class="text-danger">*</span>
                                  <input required type="text" class="form-control mt-2"
                                  [(ngModel)]="depositedata.amount"
                                  name="amount"
                                  #amount="ngModel" dir="ltr">
                                  </label>
                                  <small class="text-danger"
                                     *ngIf="amount.touched && amount.errors?.required || depositeform.submitted && amount.errors?.required">
                                  * الرجاء إدخال القيمة المطلوبة
                                  </small>
                               </div>
                            </div>
                            <!--  <div class="col-lg-12">
                               <div class="form-check form-switch">
                                   <label class="form-check-label" for="issue_invoice">
                                       اصدار فاتورة
                                   </label>
                                       <input class="form-check-input" type="checkbox" id="issueInvoiceCheckbox" [(ngModel)]="depositedata.issue_invoice">
                               </div>
                               </div> -->  
                         </div>
                         </form>
                         <div class="mt-5 text-center text-sm-end">
                            <form #depositeform="ngForm" (ngSubmit)="onSubmit(depositeform)">    
                            <div class="mt-5 text-center text-sm-end">
                               <hr>
                               <button [disabled]="buttonDisabled" type="submit" (click)="setIssueInvoiceTrue()"
                               class="btn btn-sm btn-outline-danger me-2"
                               >
                               <ng-container>
                                  <i class="fa fa-print pe-2"></i>
                                  إيداع و اصدار فاتورة
                               </ng-container>
                               </button>
                               <button [disabled]="buttonDisabled" type="submit" class="btn btn-sm btn-alofoq"
                               
                               [hidden] = "isEdit && auth && !checkPermission('prm_users_update')">
                               <ng-container >
                                  <ng-container >
                                     <i class="fa fa-money-bill-alt pe-2"></i>
                                     إيداع المبلغ
                                  </ng-container>
                               </ng-container>
                               </button>
                            </div>
                            </form>
                         </div>
                      </div>
                   </div>
                </ng-container>
             </div>
          </div>
       </div>
    </div>
    