import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChange, ViewChild } from '@angular/core';
import { ContractOverviewModel } from 'src/app/models/contract-overview';
import { SnackService } from 'src/app/services/snack.service';
import { ContractModel } from 'src/app/models/contract';
import { ApiService } from 'src/app/services/api-service.service';
import { NgForm } from '@angular/forms';
import { formatDate } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { ContractOnlineModel } from 'src/app/models/contract-online';
import { ActivateData } from 'src/app/models/ActivateData';



@Component({
  selector: 'app-renew-modal',
  templateUrl: './renew-modal.component.html',
  styleUrls: ['./renew-modal.component.scss']
})
export class RenewModalComponent implements OnInit {
  
  buttonDisabled = false;
  modalName = 'renewModal'
  isLoading: boolean = false
  isSending: boolean = false
  contractDetails: ContractModel
  ActivateData: ActivateData
  onlineContract: ContractOnlineModel[] = []
  isEdit: boolean
  auth: {}


  @Output() isClosed = new EventEmitter<string>();
  @Output() refreshParent = new EventEmitter<boolean>();

  @ViewChild('modalContent') modalContent: ElementRef;
  @ViewChild('closeButton') closeButton: ElementRef;

  constructor(private api: ApiService, private snack: SnackService, private activatedRoute: ActivatedRoute, private router: Router, private titleService: Title) {
    let id = this.activatedRoute.snapshot.paramMap.get('id');
    let isNum = (value) => /^\d+$/.test(value);
    if (id) {
      if (isNum(id)) {
        this.getContract(id)
       // this.refreshdata(id)
        this.isEdit = true
      }
      else {
        this.router.navigateByUrl("/admin");
        this.snack.showError("The id is not valid!");
      }
    } else {
      this.isEdit = false
    }
  }




  ngOnInit(): void {
    this.getAuth()
          this.setInitialValues()
          
        }
      
  setInitialValues(): void {
    this.ActivateData = {
      method: null,
      money_collected: null,
      user_id: null,
      pin: null,
      comments: null,
      user_price: null,
      issue_invoice: null,
      transaction_id: null,
      activation_units:null,
      invoice_id:null,
      user_username:null,
      amount:null,
      change_type: 'immediate',
      manager_balance: null,


    }
    this.contractDetails = {
      username: null,
      enabled: 1,
      password: '',
      confirm_password: '',
      profile_id: null,
      parent_id: null,
      group_id: null,
      site_id: null,
      firstname: null,
      lastname: null,
      company: null,
      email: null,
      phone: null,
      city: null,
      address: null,
      mobile: null,
      street: null,
      national_id: null,
      contract_id: 'جاري المعالجة ...',
      notes: null,
      auto_renew: 0,
      expiration: formatDate(new Date(), 'YYYY-MM-dd HH:mm:ss', 'en'),
      simultaneous_sessions: 1,
      static_ip: null,
      mikrotik_winbox_group: null,
      mikrotik_framed_route: null,
      mikrotik_addresslist: null,
      mikrotik_ipv6_prefix: null,
      user_type: 0,
      gps_lat: null,
      gps_lng: null,

    }

  }

  getContract(id) {
    this.isLoading = true
    this.api.get('user/' + id).subscribe(
      res => {
        this.contractDetails = res['data']
        this.getActivateData(id)
        this.titleService.setTitle(`الأفق الرقمي لخدمات الإنترنت - نظام إدارة عقود المشتركين | ${this.contractDetails.contract_id} | ${this.contractDetails.username}`);

      }, err => {
        this.router.navigateByUrl("/admin")
        this.snack.showError('There is something wrong. Please check the console!')
        this.isLoading = false
        console.log(err)
      })
  }



  getActivateData(id) {
    this.api.get('user/activationData/' + id).subscribe(
      res => {
        this.isLoading = false
        this.ActivateData = res['data']
        this.ActivateData.money_collected = 1
        this.ActivateData.method = 'credit'
      }, err => {
        this.router.navigateByUrl("/admin")
        this.snack.showError('There is something wrong. Please check the console!')
        this.isLoading = false
        console.log(err)
      })    
  }

  refreshdata(id) {
   // setInterval(() => {
    this.api.get('user/activationData/' + id).subscribe(
      res => {
        this.ActivateData = res['data']
        this.setInitialValues
        this.ActivateData.money_collected = 1
        this.ActivateData.method = 'credit'
      }
      )
   // }, 10000);
  }


  onSubmit(form: NgForm): void {
    this.ActivateData.user_id = this.contractDetails.id
    if(form.valid) {
        this.buttonDisabled = true;


      if(this.isEdit) {
        this.activate(this.contractDetails.id)
        setTimeout(() => {
          this.refreshdata(this.contractDetails.id)
        }, 2000);

      } 
      setTimeout(() => {
        this.buttonDisabled = false;
      }, 10000);

    } else {
      this.snack.showError('الرجاء التحقق من إدخال كافة البيانات بشكل صحيح.')
    }      
  }


activate(id) {
  this.isSending = true
      this.api.post('user/activate', this.ActivateData, true).subscribe
      (
        
        res => {
          if (res['status'] == 200 && res['message'] == 'rsp_success') {
            this.snack.showSuccess('تم تفعيل المشترك بنجاح')
            this.closeModal();
           // setTimeout(() => {
           // window.location.reload();
           // }, 5000);
                      } else {
            if (res['status'] == -1 && res['message'] == 'rsp_insufficient_balance') {
              this.snack.showError('الرصيد غير كافي لأكمال عملية التفعيل.')
            } 
          }
           {
            if (res['invoice_id']) {
              const invoice_id = res.invoice_id
              this.api.get('userInvoice/download/' + res.invoice_id, {responseType: 'blob'})
                .subscribe(
                  
                  res => {
                    const pdfData = new Blob([res], { type: 'application/pdf' });
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(pdfData);
                    link.download = `invoice-${invoice_id}.pdf`;
                    link.click();
                  });
              }
            }

      }, err => {
        this.snack.showError('There is something wrong. Please check the console!')
        this.isLoading = false
        console.log(err);
      })
 
  }

  checkIfClickedInside = (event: Event) => {
    let isClickInside = this.modalContent.nativeElement.contains(event.target);
    if (!isClickInside) {
      this.closeModal()
    }    
  }

  closeModal(){
    this.closeButton.nativeElement.click()
    this.changeStatusToClosed(this.modalName)
    document.removeEventListener("click", this.checkIfClickedInside, true);
  }

  changeStatusToClosed(modalName) {    
    this.isClosed.emit(modalName);
  }

  getAuth(): void {
    this.isLoading = true
    this.api.get('auth').subscribe(
      res => {
        this.auth = res
        if(!this.isEdit) {
          if(!this.checkPermission('prm_users_create')) {
            this.snack.showError("لا يسمح لك بإضافة المستخدمين")
            this.router.navigateByUrl("/admin")
            
          }
          this.contractDetails.parent_id = res['client']['id']
        }

        this.isLoading = false
      },
      err => {
        this.snack.showError('There is something wrong. Please check the console!')
        this.isLoading = false
      }
    )
  }

  checkPermission(permissionType: string) {    
    //console.log(permissionType, " => ", this.auth['permissions'].includes(permissionType));
    return this.auth['permissions'].includes(permissionType);
  }


}

