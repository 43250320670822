import {Component, ElementRef, EventEmitter,  OnInit, Output, ViewChild,} from "@angular/core";
import { SnackService } from "src/app/services/snack.service";
import { ContractModel } from "src/app/models/contract";
import { ApiService } from "src/app/services/api-service.service";
import { NgForm } from "@angular/forms";
import { formatDate } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { ActivateData } from 'src/app/models/ActivateData';


@Component({
  selector: "app-redeem-modal",
  templateUrl: "./redeem-modal.component.html",
  styleUrls: ["./redeem-modal.component.scss"],
})
export class RedeemModalComponent implements OnInit {
  modalName = "redeemModal";
  isLoading: boolean = false;
  isSending: boolean = false;
  issue_invoice: boolean = false;
  contractDetails: ContractModel;
  isEdit: boolean;
  auth: {};
  authDetails: {};
  depositedata: { user_id: any; user_username: any; amount: any };
  buttonDisabled = false;
  ActivateData: ActivateData

  @Output() isClosed = new EventEmitter<string>();
  @Output() refreshParent = new EventEmitter<boolean>();

  @ViewChild("modalContent") modalContent: ElementRef;
  @ViewChild("closeButton") closeButton: ElementRef;

  constructor(
    private api: ApiService,
    private snack: SnackService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private titleService: Title
  ) {
    let id = this.activatedRoute.snapshot.paramMap.get("id");
    let isNum = (value) => /^\d+$/.test(value);
    if (id) {
      if (isNum(id)) {
        this.getContract(id);
        this.isEdit = true;
      } else {
        this.router.navigateByUrl("/admin");
        this.snack.showError("The id is not valid!");
      }
    } else {
      this.isEdit = false;
    }
  }

  ngOnInit(): void {
    this.getAuth();
    this.setInitialValues();
  }

  setInitialValues(): void {
    this.contractDetails = {
      username: null,
      enabled: 1,
      password: "",
      confirm_password: "",
      profile_id: null,
      parent_id: null,
      group_id: null,
      site_id: null,
      firstname: null,
      lastname: null,
      company: null,
      email: null,
      phone: null,
      city: null,
      address: null,
      mobile: null,
      street: null,
      national_id: null,
      contract_id: "جاري المعالجة ...",
      notes: null,
      auto_renew: 0,
      expiration: formatDate(new Date(), "YYYY-MM-dd HH:mm:ss", "en"),
      simultaneous_sessions: 1,
      static_ip: null,
      mikrotik_winbox_group: null,
      mikrotik_framed_route: null,
      mikrotik_addresslist: null,
      mikrotik_ipv6_prefix: null,
      user_type: 0,
      gps_lat: null,
      gps_lng: null,

    };
    this.depositedata = {
      user_id: null,
      user_username: null,
      amount: null,
    };
    this.ActivateData = {
      method: null,
      money_collected: null,
      user_id: null,
      pin: null,
      comments: null,
      user_price: null,
      issue_invoice: null,
      transaction_id: null,
      activation_units:null,
      invoice_id:null,
      user_username:null,
      amount:null,
      change_type: 'immediate',
      manager_balance: null,


    }

  }

  getContract(id) {
    this.isLoading = true;
    this.api.get("user/" + id).subscribe(
      (res) => {
        this.contractDetails = res["data"];
        this.getActivateData(id)
      },
      (err) => {
        this.router.navigateByUrl("/admin");
        this.snack.showError(
          "There is something wrong. Please check the console!"
        );
        this.isLoading = false;
        console.log(err);
      }
    );
    
  }
  setIssueInvoiceTrue() {
    this.issue_invoice = true;
    console.log(this.issue_invoice);

}

depositeandissueinvoice(id) {
  this.isSending = true;
      this.api.post("user/deposit", this.depositedata, true).subscribe(
        (res) => {
          if (res["status"] == 200 && res["message"] == "rsp_amount_added") {
            this.snack.showSuccess("تم إضافة المبلغ بنجاح");
            this.issueinvoice(id)
            this.closeModal();
          } else if (res["status"] == -1 && res["message"] == "rsp_insufficient_balance") {
            this.snack.showError("رصيدك غير كافي لإجراء هذه العملية");
          }
    
      console.log(this.depositedata);
    },
    (err) => {
      this.snack.showError(
        "There is something wrong. Please check the console!"
      );
      this.isLoading = false;
      console.log(err);
    }
  );
}
deposite(id) {
  this.isSending = true;
  this.api.post("user/deposit", this.depositedata, true).subscribe(
    (res) => {
      if (res["status"] == 200 && res["message"] == "rsp_amount_added") {
        this.snack.showSuccess("تم إضافة المبلغ بنجاح");
        this.closeModal();
        // setTimeout(() => {
        //   window.location.reload();
        // }, 5000);
      } else if (res["status"] == -1 && res["message"] == "rsp_insufficient_balance") {
        this.snack.showError("رصيدك غير كافي لإجراء هذه العملية"); // Display the message
      }
      console.log(this.depositedata);
    },
    (err) => {
      this.snack.showError(
        "There is something wrong. Please check the console!"
      );
      this.isLoading = false;
      console.log(err);
    }
  );
}

  
  issueinvoice(id) {
    const invoiceData = {
      items: [
        {
          qty: 1,
          unit_price: this.depositedata.amount,
          total: this.depositedata.amount,
          name: "رصيد إلكتورني",
        },
      ],
      due_date: "",
      client_id: this.contractDetails.id,
      discount: 0,
      total: this.depositedata.amount,
      comment: "",
    };
    console.log(invoiceData);
    if (this.depositedata.amount === null) {
      this.snack.showError("الرجاء التحقق من إدخال كافة البيانات بشكل صحيح.");
      return;
    }
    this.api.post("user/invoice", invoiceData, true).subscribe(
      (res) => {
        if (res["status"] == 200 && res["message"] == "rsp_save_success") {
          this.snack.showSuccess("تم اصدار فاتورة بنجاح");
          this.closeModal();
        }
        {
          {
            this.api.get("user/invoice/pay/" + res.data.id).subscribe();
          }
          {
            setTimeout(() => {
              const invoice_id = res.data.id;
              this.api
                .get("userInvoice/download/" + res.data.id, {
                  responseType: "blob",
                })
                .subscribe((res) => {
                  const pdfData = new Blob([res], { type: "application/pdf" });
                  const link = document.createElement("a");
                  link.href = window.URL.createObjectURL(pdfData);
                  link.download = `invoice-${invoice_id}.pdf`;
                  link.click();
                });
            }, 5000);
          }
        }
      },
      (err) => {
        this.snack.showError(
          "There is something wrong. Please check the console!"
        );
        console.log(err);
      }
    );
  }

  
  refreshdata(id) {
    this.api.get('user/activationData/' + id).subscribe(
      res => {
        this.ActivateData = res['data']
        this.setInitialValues
      })    
  }
  getActivateData(id) {
    this.api.get('user/activationData/' + id).subscribe(
      res => {
        this.ActivateData = res['data']
      })    
  }

  onSubmit(form: NgForm): void {
    this.depositedata.user_id = this.contractDetails.id;
    this.depositedata.user_username = this.contractDetails.username;

    if (form.valid) {
      this.buttonDisabled = true;

      if (this.issue_invoice) {
        this.depositeandissueinvoice(this.contractDetails.id);
      } else {
        this.deposite(this.contractDetails.id)
      }
      setTimeout(() => {
        this.refreshdata(this.contractDetails.id)
      }, 2000);

      setTimeout(() => {
        this.buttonDisabled = false;
      }, 10000);
      setTimeout(() => {
        this.issue_invoice = false;
        console.log(this.issue_invoice);
      }, 2000);

    } else {
      this.snack.showError("الرجاء التحقق من إدخال كافة البيانات بشكل صحيح.");
    }
    console.log();
  }

  checkIfClickedInside = (event: Event) => {
    let isClickInside = this.modalContent.nativeElement.contains(event.target);
    if (!isClickInside) {
      this.closeModal();
    }
  };

  closeModal() {
    this.closeButton.nativeElement.click();
    this.changeStatusToClosed(this.modalName);
    document.removeEventListener("click", this.checkIfClickedInside, true);
  }

  changeStatusToClosed(modalName) {
    this.isClosed.emit(modalName);
  }

  getAuth(): void {
    this.isLoading = true;

    this.api.get("auth").subscribe(
      (res) => {
        this.authDetails = res;
        this.auth = res;
        if (!this.isEdit) {
          if (!this.checkPermission("prm_users_create")) {
            this.snack.showError("لا يسمح لك بإضافة المستخدمين");
            this.router.navigateByUrl("/admin");
          }
          this.contractDetails.parent_id = res["client"]["id"];
        }

        this.isLoading = false;
      },
      (err) => {
        this.snack.showError(
          "There is something wrong. Please check the console!"
        );
        this.isLoading = false;
        console.log(err);
      }
    );
  }

  checkPermission(permissionType: string) {
    //console.log(permissionType, " => ", this.auth['permissions'].includes(permissionType));
    return this.auth["permissions"].includes(permissionType);
  }
}
