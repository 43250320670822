<div class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" [id]="modalName" tabindex="-1" aria-labelledby="renewModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
        <div #modalContent class="modal-content  glassmorphism-card rounded-lg px-2 px-lg-5 py-3">
            <div class="modal-header  border-bottom-0 pb-0">
                <button title="تحديث بيانات المشترك" (click)="refreshdata(contractDetails.id)"
                class="btn btn-sm btn-alofoq me-2">
                   <i class="fa fa-sync "></i>
                </button>

                <i class="fa fa-puzzle-piece pe-2"></i>
                تغيير باقة العقد 
                ( {{contractDetails.username}} , {{contractDetails.firstname}} {{contractDetails.lastname}} )

<button (click)="changeStatusToClosed(modalName)" #closeButton type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" title="إغلاق"></button>
            </div>
               <hr>
               <div  *ngIf="(isEdit && !isLoading) || (!isEdit)">
                <div class="row">
                    <ng-container>
                        <div class="col-12 col-xl">
                            <div >
                                <div class="card-body">
                                    <div class="row text-center text-xl-start">
                                        <div class="col-md-4 col-lg-3  col-6 ">
                                            <small class="d-block">
                                                <b>الباقة الحالية</b>
                                            </small>
                                            <div dir="ltr">
                                                <small>{{contractDetails.profile_name}}</small>                                    
                                            </div>
                                        </div>
                                        <div class="col-md-4 col-lg-3  col-6 ">
                                            <small class="d-block">
                                                <b>تنتهي بتاريخ</b>
                                            </small>
                                            <div dir="ltr">
                                                <small>{{contractDetails.expiration}}</small>                                    
                                            </div>
                                        </div>
                                </div>
                                            </div>
                                            <form #changeprofileform="ngForm" (ngSubmit)="onFormSubmit(changeprofileform)">
                                                <div class="card-body">
                                                    <hr>
                                                    <div class="row gy-4">
                                                        <input type="hidden"  
                                                        [(ngModel)]="contractDetails.id"
                                                        name="user_id"
                                                        #user_id="ngModel" dir="ltr">
                                                         <div class="col-lg-12">
                                                            <div class="col-md-4 col-xl-4">
                                                                <label class="w-100">
                                                                    الباقة
                                                                    <span class="text-danger">*</span>
                                                                    <select required class="form-select mt-2"
                                                                        [(ngModel)]="ChangeProfile.profile_id" name="profile_id"
                                                                        #profile_id="ngModel"
                                                                        >
                                                                        <option *ngFor="let item of profiles" [value]="item.id">{{item.name}}
                                                                        </option>
                                                                    </select>
                                                                </label>
                                                                <small class="text-danger"
                                                                    *ngIf="profile_id.touched && profile_id.errors?.required || changeprofileform.submitted && profile_id.errors?.required">
                                                                    * الرجاء تحديد باقة المشترك
                                                                </small>
                                                            </div>
                                                                    
                                                                <div class="col-lg-4">
                                                                    <label class="w-100" required>
                                                                        موعد التغيير
                                                                        <span class="text-danger">*</span>
                                                                        <select required class="form-select mt-2" 
                                                                            [(ngModel)]="ChangeProfile.change_type" name="change_type"
                                                                            #change_type="ngModel">
                                                                            <option value="immediate" >حالاً </option>
                                                                            <option value="schedule">عند إنتهاء الاشتراك</option>
                                                                        </select>
                                                                    </label>
                                                                    <small class="text-danger"
                                                                    *ngIf="change_type.touched && change_type.errors?.required || changeprofileform.submitted && change_type.errors?.required">
                                                                    * الرجاء إختيار وسيلة التفعيل
                                                                </small>
                            
                                                                </div>
                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        <div class="card-body">
                                            <form #changeprofileform="ngForm" (ngSubmit)="onSubmit(changeprofileform)">    
                                                <div class=" text-center text-sm-end">
                                                    <hr>
                                                    <button type="submit" [disabled]="buttonDisabled" class="btn btn-sm btn-alofoq">

                                                        <ng-container >
                                                            <ng-container >
                                                                <i class="fa fa-puzzle-piece pe-2"></i>
                                                                تغيير باقة العقد
                                                            </ng-container>
                                                        </ng-container>
                                                        <ng-container *ngIf="isLoading">
                                                            الرجاء الإنتظار...
                                                        </ng-container>
                                                    </button>
                                                </div>
                        
                                            </form>
                                              </div>
                                    </div>
                        </div>
                    </ng-container>
                </div>
            



        </div>
    </div>
</div>