import { ApiService } from './../../services/api-service.service';
import { AuthService } from './../../services/auth.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard-layout',
  templateUrl: './dashboard-layout.component.html',
  styleUrls: ['./dashboard-layout.component.scss']
})
export class DashboardLayoutComponent implements OnInit {
  
  authDetails: {}
  constructor(private auth: AuthService, private api: ApiService) {
    this.api.get('auth').subscribe(res => {
      this.authDetails = res;
    });
   }

  ngOnInit() {
  }


  logout() {
    this.auth.operations.logout();
  }
  checkPermission(permissionType: string) {    
    //console.log(permissionType, " => ", this.auth['permissions'].includes(permissionType));
    return this.authDetails['permissions'].includes(permissionType);
  }

}

