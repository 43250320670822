    <!-- data-bs-backdrop="static" -->
    <div  data-bs-backdrop="static" data-bs-keyboard="false"  tabindex="-1" aria-labelledby="changePackageModalLabel" aria-hidden="true">
      <div>
      <div >
      <div class="modal-header border-bottom ">
         <h4 class="modal-title  font-weight-bold" id="changePackageModalLabel">تسجيل الدخول بإستخدام حساب أخر</h4>
      </div>
      <div class="modal-body pt-0">
      <div class="">
      <ng-container >
      <ng-container >
      <div class="container">
         <div class="row h-100 mt-4">
            <form #LoginAsform="ngForm" (ngSubmit)="onSubmit(LoginAsform)">
            <div class="card-body">
               <div class="row gy-4">
                 <div class="col-md-4 col-xl-4">
                    <label class="w-100">
                      إختيار حساب
                      <span class="text-danger">*</span>
                      <input appAutofocus list="managers" name="manager"
                      class="form-select mt-2" required 
                      [(ngModel)]="LoginAs.manager_id" #manager_id="ngModel">
                      <datalist id="managers">
                        <option *ngFor="let item of parents | menuSorting: 'username'" [value]="item.id">{{item.username}}</option>
                      </datalist>
                    </label>
                    <small class="text-danger" *ngIf="manager_id.touched && manager_id.errors?.required || LoginAsform.submitted && manager_id.errors?.required">
                      *الرجاء تحديد اسم مدير النظام 
                    </small>
                  </div>
               </div>
            </div>
            </form>
            <div class="card-body">
               <form #LoginAsform="ngForm" (ngSubmit)="onSubmit(LoginAsform)">    
               <div class=" text-center text-sm-end">
                  <hr>
                  <button type="submit" class="btn btn-sm btn-alofoq"
                     >
                     <ng-container >
                        <ng-container >
                           <i class="fa fa-user-secret pe-2"></i>
                           تسجيل الدخول
                        </ng-container>
                     </ng-container>
                  </button>
               </div>
               </form>
            </div>
         </div>
         <ng-container >
            <div class="row mt-4">
               <div class="col-12">
               </div>
            </div>
         </ng-container>
      </div>
      