

<div class="d-flex justify-content-center vh-100 align-items-center" *ngIf="isEdit && isLoading">
    <div class="spinner-border text-alofoq m-3" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    الرجاء الإنتظار ...
</div>
<div class="container pt-5" *ngIf="(isEdit && !isLoading)">
    <div class="row">
        <ng-container >
            <div class="col-12 col-xl">
                
                <div class="card hover-shadow shadow-sm" >
                    <div class="card-body">
                            <fieldset>
                                <div class="row">
                                    <a class="col-6 col-xl-3 col-md-6" style="text-decoration:none" *ngIf="checkPermission('prm_users_activate')"                                         
                                    data-bs-toggle="modal"
                                    data-bs-target="#renewModal"
                                    #openRenewModalButton
                                    (click)="showRenewModal = true">                                        
                                    <div class="card shadow-sm hover-shadow my-3" >
                                            <div class="card-body" >
                                                <div class="float-right">
                                                    <i class="fa fa-bolt text-alofoq h4 ml-3"></i>
                                                </div>
                                                <p class="text-muted mb-0">تفعيل</p>
                                            </div>
                                        </div>
                                    </a>
                                    <a class="col-6 col-xl-3 col-md-6" style="text-decoration:none" *ngIf="checkPermission('prm_users_deposit')"                                         
                                    data-bs-toggle="modal"
                                    data-bs-target="#redeemModal"
                                    (click)="showRedeemModal = true">                                            
                                    <div class="card shadow-sm hover-shadow my-3" >
                                            <div class="card-body" >
                                                <div class="float-right">
                                                    <i class="fa fa-money-bill-alt text-alofoq h4 ml-3"></i>
                                                </div>
                                                <p class="text-muted mb-0">إيداع</p>
                                            </div>
                                        </div>
                                    </a>
                                    <a class="col-6 col-xl-3 col-md-12" style="text-decoration:none" *ngIf="checkPermission('prm_users_change_profile')"
                                    data-bs-toggle="modal"
                                    data-bs-target="#changePackageModal"
                                    (click)="showChangePackageModal = true">                                        <div class="card shadow-sm hover-shadow my-3">
                                            <div class="card-body">
                                                <div class="float-right">
                                                    <i class="fa fa-puzzle-piece text-alofoq h4 ml-3"></i>
                                                </div>
                                                <p class="text-muted mb-0">تغيير الباقة</p>
                                            </div>
                                        </div>
                                    </a>                                                  
                                </div>
                           </fieldset>    
                    </div>
                </div>
            </div>
        </ng-container>
    </div>

</div>

<div class="container pt-5" *ngIf="(isEdit && !isLoading) || (!isEdit)">
    <div class="row">
        <ng-container *ngIf="isEdit && contractOverview">
            <div class="col-12 col-xl-4 mb-5 mb-xl-0">
                <div class="card hover-shadow">
                    <div class="card-header bg-dark text-light">
                        <div class="row">
                            <div class="col-lg-12">
                                <i class="fa fa-book pe-2"></i>
                                معلومات الأتصال
                            </div>
                        </div>
                    </div>
                    
                    <div class="card-body">
                        <div class="row text-center text-xl-start">
                            <div class="col-md-4 col-lg-3 col-xl-6 col-6 my-3">
                                <small class="d-block">
                                    <b>اخر اتصال</b>
                                </small>
                                <div dir="ltr">
                                    <small>{{contractOverview.last_online || 'غير محدد'}}</small>                                    
                                </div>
                            </div>
                            <div class="col-md-4 col-lg-3 col-xl-6 col-6 my-3"  *ngIf="onlineContract">
                                <small class="d-block">
                                    <b>مدة الأتصال</b>
                                </small>
                                <div >
                                    {{onlineContract == '' ? 'غير محدد' : ''}}
                                    <ul class="list-unstyled">
                                        <li *ngFor="let item of onlineContract">
                                                {{((item.acctsessiontime || 0) | secondsToAgo) || 0}}

                                        </li>
                                    </ul>
                                </div>
                            </div>                                                                                                                           

                            <div class="col-md-4 col-lg-3 col-xl-6 col-6 my-3">
                                <small class="d-block">
                                    <b>حالة الإتصال</b>
                                </small>
                                <div dir="ltr" class="badge" [ngClass]="onlineContract != '' ? 'bg-success' : 'bg-danger'">
                                    {{onlineContract != '' ? 'Online' : 'Offline'}}
                                </div>
                             </div>                             
                            <div class="col-md-4 col-lg-3 col-xl-6 col-6 my-3"  *ngIf="onlineContract">
                                <small class="d-block">
                                    <b>Last IP Address</b>
                                </small>
                                <div dir="ltr">
                                    {{onlineContract == '' ? 'غير متصل' : ''}}
                                    <ul class="list-unstyled">
                                        <li *ngFor="let item of onlineContract">
                                            <a class="text-decoration-none" [href]="'http://' + item.framedipaddress" target="_blank">
                                                {{item.framedipaddress}}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>                                                                                                                           
                        </div>
                        <span class="text-center">
                            <button *ngIf="checkPermission('prm_users_disconnect')" class="btn btn-sm btn-alofoq"
                                type="button"
                                (click)="disconnect()">
                                <i class="fa fa-unlink pe-2"></i>
                                قطع الأتصال 
                            </button>
                        </span>     
    
                    </div>
                    <div class="card-header bg-dark text-light">
                        <div class="row">
                            <div class="col-lg-12">
                                <i class="fa fa-book pe-2"></i>
                                نظرة عامة
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row text-center text-xl-start">
                            <div class="col-md-4 col-lg-3 col-xl-6 col-6 my-3">
                                <small class="d-block">
                                    <b>الاسم الاول</b>
                                </small>
                                {{contractOverview.firstname || 'غير محدد'}}
                            </div>
                            <div class="col-md-4 col-lg-3 col-xl-6 col-6 my-3">
                                <small class="d-block">
                                    <b>الاسم الاخير</b>
                                </small>
                                {{contractOverview.lastname || 'غير محدد'}}
                            </div>
                            <div class="col-md-4 col-lg-3 col-xl-6 col-6 my-3">
                                <small class="d-block">
                                    <b>تابع إلى</b>
                                </small>
                                {{contractOverview.parent_username || 'غير محدد'}}
                            </div>
                            
                            <div class="col-md-4 col-lg-3 col-xl-6 col-6 my-3">
                                <small class="d-block">
                                    <b>الرصيد</b>
                                </small>
                                {{contractOverview.balance || 0 }} د.ل
                            </div>
                            <div class="col-md-4 col-lg-3 col-xl-6 col-6 my-3">
                                <small class="d-block">
                                    <b>تاريخ الإنتهاء</b>
                                </small>
                                <div dir="ltr">
                                    <small>{{contractOverview.expiration.replace(' ',' - ')}}</small>
                                    
                                </div>
                            </div>
                            <div class="col-md-4 col-lg-3 col-xl-6 col-6 my-3">
                                <small class="d-block">
                                    <b>كمية البيانات المتبقية</b>
                                </small>
                                <div dir="ltr">
                                    {{((contractOverview.remaining_rxtx || 0) | formatBytes) || 0}}
                                </div>
                            </div>  
                            <div class="col-md-4 col-lg-3 col-xl-6 col-6 my-3">
                                <small class="d-block">
                                    <b>حالة الإشتراك</b>
                                </small>
                                <span [class]="'badge bg-' + (contractDetails.status | contractStatus).bootstrapClass">
                                    {{(contractDetails.status | contractStatus).status}}
                                </span>
                                <span class="badge bg-danger ms-2" *ngIf="contractDetails.enabled == 0">
                                    موقوف
                                </span>                                
                            </div>
                            <div class="col-md-4 col-lg-3 col-xl-6 col-6 my-3">
                                <small class="d-block">
                                    <b>الباقة</b>
                                </small>
                                {{contractOverview.profile_name || 'غير محدد'}}
                            </div>                                                                                                                        
                            <div class="col-md-4 col-lg-3 col-xl-6 col-6 my-3">
                                <small class="d-block">
                                    <b>اضيف بتاريخ</b>
                                </small>
                                <div dir="ltr">
                                    <small>{{contractOverview.created_at}}</small>                                    
                                </div>
                            </div>
                            <div class="col-md-4 col-lg-3 col-xl-6 col-6 my-3">
                                <small class="d-block">
                                    <b>اضيف من قبل</b>
                                </small>
                                {{contractOverview.created_by}}
                            </div> 
                            <div class="col-md-4 col-lg-3 col-xl-6 col-6 my-3">
                                <small class="d-block">
                                    <b>محاولات التعبئة الخاطئة</b>
                                </small>
                                {{contractOverview.pin_tries || 0}}
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        
        <ng-container *ngIf="(isEdit && contractOverview) || (!isEdit && !isLoading)">
            <div class="col-12 col-xl">
                <div class="card hover-shadow shadow-sm">
                    <div class="card-header bg-dark text-light">
                        <div class="row">
                            <div class="col-lg-12">
                                <i class="fa fa-user pe-2"></i>
                                <ng-container *ngIf="!isEdit">
                                    إضافة عقد جديد
                                </ng-container>
                                <ng-container *ngIf="isEdit">
                                    بيانات العقد 
                                   ( {{contractDetails.username}} , {{contractDetails.firstname}} {{contractDetails.lastname}} )

                                   <button title="نسخ بيانات المشترك" class="btn btn-sm btn-alofoq me-2" type="button" (click)="copyMessage((contractOverview.username || '') + '\n' + (contractDetails.password || '') + '\n' + (contractDetails.firstname || '') + ' ' + (contractDetails.lastname || '') + '\n' + (contractDetails.city || '') +  ',' + (contractDetails.address || '') +  ',' + (contractDetails.street || '') + '\n' + (contractDetails.phone || '') + '\n' + (contractDetails.mobile || '') + '\n' + ' ' + '\n' + 'https://www.google.com/maps/search/?api=1&query=' + (contractDetails.gps_lat || '') + ',' + (contractDetails.gps_lng || ''))">
                                    <i class="fa fa-clipboard"></i>
                                  </button>
                                                                      
                                    <button title="تحديث بيانات المشترك" (click)="refreshdata(contractDetails.id)"
                                    class="btn btn-sm btn-alofoq me-2">
                                       <i class="fa fa-sync "></i>
                                    </button>
                                </ng-container>
                                
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <form #contractForm="ngForm" (ngSubmit)="onSubmit(contractForm)">
                            <fieldset [disabled]="auth && !checkPermission('prm_users_update')">
    
                            <div class="mt-2">
                                <h4>معلومات عامة</h4>
                                <hr>
                                <div class="row gy-12">
                                    <div class="col-lg-12">
                                        <div class="form-check form-switch float-start">
                                            <label class="form-check-label" for="enabled_input">
                                                فعال
                                            </label>
                                            <input class="form-check-input" type="checkbox" id="enabled_input"
                                                [(ngModel)]="contractDetails.enabled" name="enabled" #enabled="ngModel">
                                        </div>
                                    </div>
                                    <span class="text-center text-sm-end" *ngIf="!isEdit">
                                        <button class="btn btn-sm btn-alofoq"
                                            type="button"
                                            (click)="generatePassword()">
                                            <i class="fa fa-plus pe-2"></i>
                                            توليد كلمة سر جديدة 
                                        </button>
                                    </span>     
                                    <div class="col-md-4 col-xl-4">
                                        <label class="w-100">
                                            رقم العقد
                                            <input type="text" disabled class="form-control mt-2"
                                                [value]="contractDetails.contract_id" name="contract_id">
                                        </label>
                                    </div>                                    
                                    <div class="col-md-4 col-xl-4">
                                        <label class="w-100">
                                            اسم المستخدم
                                            <input type="text" disabled class="form-control mt-2"
                                                [value]="contractDetails.username" name="contract_id">
                                        </label>
                                    </div>                                    
                                  <!--  <div class="col-md-4 col-xl-4">
                                        <label class="w-100">
                                            اسم المستخدم
                                            <span class="text-danger">*</span>
                                            <input type="text" disabled required class="form-control mt-2"
                                            [(ngModel)]="contractDetails.username" name="username"
                                                pattern="[a-zA-Z.@_0-9:-]*" #name="ngModel" [disabled]="isEdit">
                                        </label>
                                        <small class="text-danger"
                                            *ngIf="name.touched && name.errors?.required || contractForm.submitted && name.errors?.required">
                                            * الرجاء إدخال اسم الدخول للمشترك
                                        </small>
                                        <small class="text-danger"
                                            *ngIf="name.touched && name.errors?.pattern || contractForm.submitted && name.errors?.pattern">
                                            * اسم الدخول يجب أن يبدأ بحروف او ارقام وان لا يحتوي على مسافات
                                        </small>
                                    </div> -->
                                    <div class="col-md-4 col-xl-4">
                                        <label class="w-100">
                                            كلمة المرور
                                            <div class="input-group mt-2">
                                                <input disabled   
                                                    [type]="!isPasswordHidden || (isEdit && auth && !checkPermission('prm_users_update')) ? 'text' : 'password'"
                                                    class="form-control"
                                                    [(ngModel)]="contractDetails.password"
                                                    name="password"
                                                    #password="ngModel" 
                                                    value="{{ generatedpassword }}">
                                                <span class="input-group-text p-0 bg-white">
                                                    <button 
                                                        type="button"
                                                        (click)="showHidePassword()"
                                                        class="btn btn-sm"
                                                        [title]="(isPasswordHidden ? 'عرض' : 'اخفاء') + ' كلمة المرور'">
                                                        <i class="fa mt-1 px-1" [ngClass]="isPasswordHidden ? 'fa-eye' : 'fa-eye-slash'"></i>
                                                    </button>
                                                </span>
                                            </div>
                                        </label>
                                    </div>
                                    <div class="col-md-4 col-xl-4">
                                        <label class="w-100">
                                            تأكيد كلمة المرور
                                            <div class="input-group mt-2">
                                                <input  disabled
                                                    [type]="!isPasswordHidden || (isEdit && auth && !checkPermission('prm_users_update')) ? 'text' : 'password'"
                                                    class="form-control"
                                                    [(ngModel)]="contractDetails.confirm_password"
                                                    name="confirm_password"
                                                    #confirm_password="ngModel" 
                                                    value="{{ generatedpassword }}">
                                                <span class="input-group-text p-0 bg-white">
                                                    <button type="button" (click)="showHidePassword()" class="btn btn-sm"
                                                        [title]="(isPasswordHidden ? 'عرض' : 'اخفاء') + ' كلمة المرور'">
                                                        <i class="fa mt-1 px-1" [ngClass]="isPasswordHidden ? 'fa-eye' : 'fa-eye-slash'"></i>
                                                    </button>
                                                </span>
                                            </div>
                                        </label>
                                        <small class="text-danger"
                                            *ngIf="confirm_password.touched && confirm_password.value != password.value || contractForm.submitted && confirm_password.value != password.value">
                                            * يجب ان تكون الكلمتين متطابقتين
                                        </small>
                                    </div>
                                    <div class="col-md-4 col-xl-4">
                                        <label class="w-100">
                                            الباقة
                                            <span class="text-danger">*</span>
                                            <select required class="form-select mt-2"
                                                [(ngModel)]="contractDetails.profile_id" name="profile_id"
                                                #profile_id="ngModel" prm_managers_sysadmin
                                                [disabled]="isEdit && auth && !checkPermission('prm_managers_sysadmin')">
                                                <option *ngFor="let item of profiles" [value]="item.id">{{item.name}}
                                                </option>
                                            </select>
                                        </label>
                                        <small class="text-danger"
                                            *ngIf="profile_id.touched && profile_id.errors?.required || contractForm.submitted && profile_id.errors?.required">
                                            * الرجاء تحديد باقة المشترك
                                        </small>
                                    </div>
                                    <div class="col-md-4 col-xl-4">
                                        <label class="w-100">
                                            تابع إلى
                                            <span class="text-danger">*</span>
                                            <select 
                                                required
                                                class="form-select mt-2"
                                                [(ngModel)]="contractDetails.parent_id"
                                                name="parent_id"
                                                #parent_id="ngModel"
                                            >
                                                <option
                                                    
                                                    *ngFor="let item of parents | menuSorting: 'username'"
                                                    [value]="item.id"
                                                >{{item.username}}
                                                </option>
                                            </select>
                                        </label>
                                        <small class="text-danger"
                                            *ngIf="parent_id.touched && parent_id.errors?.required || contractForm.submitted && parent_id.errors?.required">
                                            * الرجاء تحديد اسم مدير النظام الذي يتبع له المشترك
                                        </small>
                                    </div>
                                    <div class="col-md-4 col-xl-4">
                                        <label class="w-100">
                                            المجموعة
                                            <span class="text-danger">*</span>
                                            <select 
                                                required
                                                class="form-select mt-2"
                                                [(ngModel)]="contractDetails.group_id"
                                                name="group_id"
                                                #group_id="ngModel"
                                                [disabled]="isEdit && (!(contractDetails.group_id | isTower) && !checkPermission('prm_groups_index'))"
                                            >
                                                <!-- If Employee -->
                                                <ng-container *ngIf="!checkPermission('prm_groups_index')">

                                                    <ng-container *ngFor="let item of groups">
                                                        <ng-container *ngIf="(!(item.name | isTower)) || item.id == contractDetails.group_id">
                                                            <option
                                                                [value]="item.id"
                                                            >
                                                                {{item.name}}
                                                            </option>
                                                        </ng-container>
                                                    </ng-container>
                                                </ng-container>

                                                <!-- If Admin -->
                                                <ng-container *ngIf="checkPermission('prm_groups_index')">
                                                    <ng-container *ngFor="let item of groups">
                                                            <option
                                                                [value]="item.id"
                                                            >
                                                                {{item.name}}
                                                            </option>
                                                    </ng-container>
                                                </ng-container>                                                

                                            </select>
                                        </label>
                                        <small class="text-danger"
                                            *ngIf="group_id.touched && group_id.errors?.required || contractForm.submitted && group_id.errors?.required">
                                            * الرجاء تحديد اسم المجموعة التي يتبع لها المشترك
                                        </small>
                                    </div>                                
                                  <!--  <div class="col-md-4 col-xl-4" *ngIf="auth && checkPermission('prm_sites_management')">
                                        <label class="w-100">
                                            الموقع
                                            <select class="form-select mt-2" [(ngModel)]="contractDetails.site_id"
                                                name="site_id" #site_id="ngModel">
                                                <option value="null">Any</option>
                                                <ng-container *ngFor="let item of sites">
                                                    <option *ngIf="item['status'] != 0" [value]="item.id">{{item.name}}
                                                    </option>
                                                </ng-container>
                                            </select>
                                        </label>
                                    </div> -->
                                    <div class="col-md-4 col-xl-4">
                                        <label class="w-100">
                                            نوع الأشتراك
                                            <span class="text-danger">*</span>
                                            <select 
                                                required
                                                class="form-select mt-2"
                                                [(ngModel)]="contractDetails.sub_type"
                                                name="sub_type"
                                                #sub_type="ngModel">
                                                <option value="1">اشتراك منازل - جديد</option>
                                                <option value="2">اشتراك منازل - نقل</option>
                                                <option value="3">اشتراك شركات - خط مشترك</option>
                                                <option value="4">اشتراك شركات - خط مخصص</option>
                                            </select>
                                        </label>
                                        <small class="text-danger"
                                            *ngIf="sub_type.touched && sub_type.errors?.required || contractForm.submitted && sub_type.errors?.required">
                                            * الرجاء تحديد نوع الأشتراك
                                        </small>
                                    </div>                                
                                    <div class="col-md-4 col-xl-4" *ngIf="isEdit">
                                        <label class="w-100">
                                            عنوان IP ثابت
                                            <input type="text" disabled class="form-control mt-2"
                                                [value]="contractDetails.static_ip">
                                        </label>
                                    </div>                                
                                </div>
                            </div>
                            <div class="mt-5">
                                <h4>معلومات شخصية</h4>
                                <hr>
    
                                <div class="row gy-4">
                                    <div class="col-md-4 col-xl-4">
                                        <label class="w-100">
                                            الاسم الاول
                                            <input type="text" class="form-control mt-2"
                                                [(ngModel)]="contractDetails.firstname" name="firstname">
                                        </label>
                                    </div>
                                    <div class="col-md-4 col-xl-4">
                                        <label class="w-100">
                                            الاسم الاخير
                                            <input type="text" class="form-control mt-2"
                                                [(ngModel)]="contractDetails.lastname" name="lastname">
                                        </label>
                                    </div>
                                    <div class="col-md-4 col-xl-4">
                                        <label class="w-100">
                                            رقم الهوية
                                            <input type="text" class="form-control mt-2"
                                                [(ngModel)]="contractDetails.national_id" name="national_id">
                                        </label>
                                    </div>
                                    <div class="col-md-4 col-xl-4">
                                        <label class="w-100">
                                            الشركة
                                            <input type="text" class="form-control mt-2"
                                                [(ngModel)]="contractDetails.company" name="company">
                                        </label>
                                    </div>
                                    <div class="col-md-4 col-xl-4">
                                        <label class="w-100">
                                            البريد الإلكتروني
                                            <input type="email" class="form-control mt-2"
                                                [(ngModel)]="contractDetails.email" name="email">
                                        </label>
                                    </div>
                                    <div class="col-md-4 col-xl-4">
                                        <label class="w-100">
                                            رقم الهاتف 1
                                            <input type="text" class="form-control mt-2" [(ngModel)]="contractDetails.phone"
                                                name="phone">
                                        </label>
                                    </div>
                                    <div class="col-md-4 col-xl-4">
                                        <label class="w-100">
                                            رقم الهاتف 2
                                            <input type="text" class="form-control mt-2"
                                                [(ngModel)]="contractDetails.mobile" name="mobile">
                                        </label>
                                    </div>
                                    <div class="col-md-4 col-xl-4">
                                        <label class="w-100">
                                            المدينة
                                            <input type="text" class="form-control mt-2" [(ngModel)]="contractDetails.city"
                                                name="city">
                                        </label>
                                    </div>
                                    <div class="col-md-4 col-xl-4">
                                        <label class="w-100">
                                            العنوان
                                            <input type="text" class="form-control mt-2"
                                                [(ngModel)]="contractDetails.address" name="address">
                                        </label>
                                    </div>
                                    <div class="col-md-4 col-xl-4">
                                        <label class="w-100">
                                            الشارع
                                            <input type="text" class="form-control mt-2"
                                                [(ngModel)]="contractDetails.street" name="street">
                                        </label>
                                    </div>
                                    <div class="col-lg-8">
                                        <label class="w-100">
                                            ملاحظات
                                            <input type="text" class="form-control mt-2" [(ngModel)]="contractDetails.notes"
                                                name="notes">
                                        </label>
                                    </div>
                                    <div class="col-lg-12">
                                        <label class="w-100">
                                            <div class="form-check form-switch">
                                                <label class="form-check-label" for="auto_renew">
                                                    تجديد تلقائي
                                                </label>
                                                <input class="form-check-input" type="checkbox" id="auto_renew"
                                                    [(ngModel)]="contractDetails.auto_renew" name="auto_renew"
                                                    #auto_renew="ngModel">
                                            </div>
                                        </label>
                                    </div>

                                </div>
                            </div>
                            <div class="mt-5" *ngIf="auth && checkPermission('prm_users_advanced')">
                                <h4>تفاصيل خاصة لمدير النظام</h4>
                                <hr>
                                <div class="row gy-4">
                                    <div class="col-lg-4">
                                        <label class="w-100">
                                            تاريخ الإنتهاء
                                            <div class="input-group mt-2">
                                                <input type="text" class="form-control"
                                                    [(ngModel)]="contractDetails.expiration" name="expiration"
                                                    placeholder="YYYY-MM-DD hh:mm:ss"
                                                    pattern="[0-9]{4}-[0-9]{2}-[0-9]{2} [0-9]{2}:[0-9]{2}:[0-9]{2}"
                                                    #expiration="ngModel" dir="ltr">
                                                <span class="input-group-text p-0 bg-white">
                                                    <button type="button" (click)="refreshExpiration()" class="btn btn-sm"
                                                        title="تحديث الوقت الحالي">
                                                        <i class="fa fa-redo mt-1 px-1"></i>
                                                    </button>
                                                </span>
                                            </div>
                                        </label>
                                        <small class="text-danger"
                                            *ngIf="expiration.touched && expiration.errors?.pattern || contractForm.submitted && expiration.errors?.pattern">
                                            * الرجاء التحقق من إدخال التاريخ بشكل صحيح
                                        </small>
                                    </div>
                                    <div class="col-lg-4">
                                        <label class="w-100">
                                            الجلسات المتزامنة
                                            <input type="number" min="0" class="form-control mt-2"
                                                [(ngModel)]="contractDetails.simultaneous_sessions"
                                                name="simultaneous_sessions">
                                        </label>
                                    </div>
                                    <div class="col-lg-4">
                                        <label class="w-100">
                                            عنوان IP ثابت
                                            <input type="text" class="form-control mt-2"
                                                [(ngModel)]="contractDetails.static_ip" name="static_ip"
                                                pattern="^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$"
                                                #static_ip="ngModel" dir="ltr">
                                        </label>
                                        <small class="text-danger"
                                            *ngIf="static_ip.touched && static_ip.errors?.pattern || contractForm.submitted && static_ip.errors?.pattern">
                                            * الرجاء التحقق من إدخال عنوان IP بشكل صحيح
                                        </small>
                                    </div>
                                    <div class="col-lg-4">
                                        <label class="w-100">
                                            Mikrotik Winbox Group
                                            <input type="text" class="form-control mt-2"
                                                [(ngModel)]="contractDetails.mikrotik_winbox_group"
                                                name="mikrotik_winbox_group">
                                        </label>
                                    </div>
                                    <div class="col-lg-4">
                                        <label class="w-100">
                                            Mikrotik Framed Route
                                            <input type="text" class="form-control mt-2"
                                                [(ngModel)]="contractDetails.mikrotik_framed_route"
                                                name="mikrotik_framed_route">
                                        </label>
                                    </div>
                                    <div class="col-lg-4">
                                        <label class="w-100">
                                            Mikrotik Address List
                                            <input type="text" class="form-control mt-2"
                                                [(ngModel)]="contractDetails.mikrotik_addresslist"
                                                name="mikrotik_addresslist">
                                        </label>
                                    </div>
                                    <div class="col-lg-4">
                                        <label class="w-100">
                                            IPv6 Prefix
                                            <input type="text" class="form-control mt-2"
                                                [(ngModel)]="contractDetails.mikrotik_ipv6_prefix"
                                                name="mikrotik_ipv6_prefix"
                                                pattern="((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))"
                                                #mikrotik_ipv6_prefix="ngModel" dir="ltr">
                                        </label>
                                        <small class="text-danger"
                                            *ngIf="mikrotik_ipv6_prefix.touched && mikrotik_ipv6_prefix.errors?.pattern || contractForm.submitted && mikrotik_ipv6_prefix.errors?.pattern">
                                            * الرجاء كتابة ipv6 prefix بشكل صحيح
                                        </small>
                                    </div>
                                    <div class="col-lg-4">
                                        <label class="w-100">
                                            نوع المشترك
                                            <select  class="form-select mt-2"
                                                [(ngModel)]="contractDetails.user_type" name="user_type"
                                                #user_type="ngModel">
                                                <option value="0">Regular User</option>
                                                <option value="1">Wireless Client</option>
                                            </select>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
    
                            <div class="mt-5 text-center text-sm-end">
                                <hr>
                                <a href="#/admin/print-contract/{{contractDetails.id}}" target="_blank"
                                    class="btn btn-sm btn-outline-danger me-2" [disabled]="isLoading"
                                    *ngIf="isEdit && contractDetails">
                                    <ng-container>
                                        <i class="fa fa-print pe-2"></i>
                                        طباعة العقد
                                    </ng-container>
                                </a>
                                <a href="#/admin/print-pdf/{{contractDetails.id}}" target="_blank"
                                    class="btn btn-sm btn-outline-danger me-2" [disabled]="isLoading"
                                    *ngIf="isEdit && contractDetails">
                                    <ng-container>
                                        <i class="fa fa-print pe-2"></i>
                                        PDF طباعة العقد
                                    </ng-container>
                                </a>
                                <button type="submit" class="btn btn-sm btn-alofoq"
                                     [disabled]="isLoading"
                                     [hidden] = "isEdit && auth && !checkPermission('prm_users_update')"
                                >
                                    <ng-container *ngIf="!isLoading">
                                        <ng-container *ngIf="!isEdit">
                                            <i class="fa fa-plus pe-2"></i>
                                            إضافة العقد
                                        </ng-container>
                                        <ng-container *ngIf="isEdit">
                                            <i class="fa fa-check-circle pe-2"></i>
                                            حفظ التعديلات
                                        </ng-container>
                                    </ng-container>
                                    <ng-container *ngIf="isLoading">
                                        الرجاء الإنتظار...
                                    </ng-container>
                                </button>
                            </div>
    
                        </form>
    
                    </div>
                </div>
            </div>
            <div class="row py-4">
                <div class="col-12 py-5">
                    <a class="btn btn-outline-secondary btn-sm" href="javascript:history.back();"><i
                            class="fa fa-angle-double-right fa-fw pr-3"></i> عودة</a>
                </div>
            </div>            
        </ng-container>
    </div>

</div>


<app-renew-modal></app-renew-modal>
<app-redeem-modal></app-redeem-modal>
<app-change-package-modal></app-change-package-modal>
