import { Component, OnInit,} from '@angular/core';
import { ContractOverviewModel } from 'src/app/models/contract-overview';
import { SnackService } from 'src/app/services/snack.service';
import { ApiService } from 'src/app/services/api-service.service';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  
  isLoading: boolean = false
  contractOverview: ContractOverviewModel
  isEdit: boolean
  parents: []
  auth: {}
  ChangePassword ;
  searchTerm: string;
  ChangePasswordData: { password: any; confirm_password: any;};



  constructor(private api: ApiService, private snack: SnackService, private activatedRoute: ActivatedRoute, private router: Router, private titleService: Title) {
    let id = this.activatedRoute.snapshot.paramMap.get('id');
    let isNum = (value) => /^\d+$/.test(value);
    if (id) {
      if (isNum(id)) {
        this.isEdit = true
      }
      else {
        this.router.navigateByUrl("/admin");
        this.snack.showError("The id is not valid!");
      }
    } else {
      this.isEdit = false
    }
  }




  ngOnInit(): void {
    this.getAuth()
    this.setInitialValues()
  }

  setInitialValues(): void {

    this.ChangePasswordData = {
      password: null,
      confirm_password: null,
    }
    
  }


  onSubmit(form: NgForm): void {
    if(form.valid) {
      if(this.ChangePasswordData.password !== this.ChangePasswordData.confirm_password) {
        this.snack.showError('يجب ان تكون كلمة السر متطابقة.');
        return;
      }
      this.Change_Password(this.ChangePasswordData);
    } else {
      this.snack.showError('الرجاء التحقق من إدخال كافة البيانات بشكل صحيح.')
    }      
  }

  Change_Password(id) {
  
      this.api.post('manager/password', this.ChangePasswordData, true).subscribe
      (
        res => {
          if (res['status'] == 200 && res['message'] == 'rsp_update_success') {
            this.snack.showSuccess('تم تغيير كلمة السر بنجاح')
            this.isLoading = true
            this.router.navigate(['/admin'])
               } else {
                if (res['status'] == -1 && res['message'] == 'rsp_password_short') {
                  this.snack.showError('يجب ان تكون كلمة السر اكثر من 6 ارقام')
                }
              }
  }, err => {
        this.snack.showError('There is something wrong. Please check the console!')
      })
  
  }
  

  getAuth(): void {
    this.api.get('auth').subscribe(
      res => {
        this.auth = res

      },
      err => {
        this.snack.showError('There is something wrong. Please check the console!')
        this.isLoading = false
        console.log(err);
      }
    )
  }

  checkPermission(permissionType: string) {    
    //console.log(permissionType, " => ", this.auth['permissions'].includes(permissionType));
    return this.auth['permissions'].includes(permissionType);
  }

}

