import { Injectable } from '@angular/core';
declare var Snackbar: any;

@Injectable({
  providedIn: 'root'
})
export class SnackService {
  private messages: { text: string, color: string }[] = [];
  private isShowingMessages = false;

  constructor() { }

  showSuccess(text: string) {
    this.messages.push({ text: text, color: '#3cab30' }); // green color
    if (!this.isShowingMessages) {
      this.showMessages();
    }
  }

  showError(text: string) {
    this.messages.push({ text: text, color: '#cc2838' }); // red color
    if (!this.isShowingMessages) {
      this.showMessages();
    }
  }

  private showMessages() {
    if (this.messages.length === 0) {
      this.isShowingMessages = false;
      return;
    }

    this.isShowingMessages = true;
    let message = this.messages.shift();
    Snackbar.show({ text: message.text, pos: 'top-center', showAction: false, duration: 3500, backgroundColor: message.color, customClass: 'rounded-pill'});
    setTimeout(() => this.showMessages(), 3500);
  }
}
