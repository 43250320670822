import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChange, ViewChild } from '@angular/core';
import { ContractOverviewModel } from 'src/app/models/contract-overview';
import { SnackService } from 'src/app/services/snack.service';
import { ContractModel } from 'src/app/models/contract';
import { ApiService } from 'src/app/services/api-service.service';
import { NgForm } from '@angular/forms';
import { formatDate } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-change-package-modal',
  templateUrl: './change-package-modal.component.html',
  styleUrls: ['./change-package-modal.component.scss']
})
export class ChangePackageModalComponent implements OnInit {
  
  modalName = 'changePackageModal'
  contractDetails: ContractModel
  contractOverview: ContractOverviewModel
  isLoading: boolean = false
  isSending: boolean = false
  isEdit: boolean
  profiles: []
  auth: {}
  ChangeProfile: { user_id: any; profile_id: any; change_type: any; };
  buttonDisabled = false;


  @Output() isClosed = new EventEmitter<string>();
  @Output() refreshParent = new EventEmitter<boolean>();

  @ViewChild('modalContent') modalContent: ElementRef;
  @ViewChild('closeButton') closeButton: ElementRef;

  constructor(private api: ApiService, private snack: SnackService, private activatedRoute: ActivatedRoute, private router: Router, private titleService: Title) {
    let id = this.activatedRoute.snapshot.paramMap.get('id');
    let isNum = (value) => /^\d+$/.test(value);
    if (id) {
      if (isNum(id)) {
        this.getContract(id)
        this.isEdit = true
      }
      else {
        this.router.navigateByUrl("/admin");
        this.snack.showError("The id is not valid!");
      }
    } else {
      this.isEdit = false
    }
  }


  ngOnInit(): void {
    this.getAuth()
    this.setInitialValues()
    this.getProfiles()
    this.ChangeProfile.change_type = 'immediate'


  }

  setInitialValues(): void {
    this.ChangeProfile = {
      user_id: null,
      profile_id: null,
      change_type: null,

    }

    this.contractDetails = {
      username: null,
      enabled: 1,
      password: '',
      confirm_password: '',
      profile_id: null,
      parent_id: null,
      group_id: null,
      site_id: null,
      firstname: null,
      lastname: null,
      company: null,
      email: null,
      phone: null,
      city: null,
      address: null,
      mobile: null,
      street: null,
      national_id: null,
      contract_id: 'جاري المعالجة ...',
      notes: null,
      auto_renew: 0,
      expiration: formatDate(new Date(), 'YYYY-MM-dd HH:mm:ss', 'en'),
      simultaneous_sessions: 1,
      static_ip: null,
      mikrotik_winbox_group: null,
      mikrotik_framed_route: null,
      mikrotik_addresslist: null,
      mikrotik_ipv6_prefix: null,
      user_type: 0,
      gps_lat: null,
      gps_lng: null,

    }

  }

  getContract(id) {
    this.isLoading = true
    this.api.get('user/' + id).subscribe(
      res => {
        this.contractDetails = res['data']
        this.titleService.setTitle(`الأفق الرقمي لخدمات الإنترنت - نظام إدارة عقود المشتركين | ${this.contractDetails.contract_id} | ${this.contractDetails.username}`);

      }, err => {
        this.router.navigateByUrl("/admin")
        this.snack.showError('There is something wrong. Please check the console!')
        this.isLoading = false
        console.log(err)
      })
  }

  getProfiles(): void {
    this.isLoading = true
    this.api.get('list/profile/5').subscribe(
      res => {
        this.profiles = res['data'].filter(profile => profile.id !== this.contractDetails.profile_id)
        this.isLoading = false  
      },
      err => {
        this.snack.showError('There is something wrong. Please check the console!')
        this.isLoading = false
        console.log(err);
      }
    )
  }  
  
  

 changeProfile(id) {

    this.api.post('user/changeProfile', this.ChangeProfile, true).subscribe
    (
    res => {
      if (res['status'] == 200) {
        this.snack.showSuccess('تم تغيير الباقة بنجاح')
        this.closeModal();
        //setTimeout(() => {
        //  window.location.reload();
        // }, 2000);
  } else {
        this.snack.showError(res['message'])
      
      }

    }, err => {
      this.snack.showError('There is something wrong. Please check the console!')
      console.log(err);
    })

}

refreshdata(id) {
  this.api.get('user/' + id).subscribe(
    res => {
      this.contractDetails = res['data']
      this.getProfiles
      this.setInitialValues
    },
    (err) => {
      console.log(err);
    }
  );
}


onSubmit(form: NgForm): void {
  this.ChangeProfile.user_id = this.contractDetails.id
  if(form.valid) {
    this.buttonDisabled = true;


    if(this.isEdit) {
      this.changeProfile(this.contractDetails.id)
      setTimeout(() => {
        this.refreshdata(this.contractDetails.id)
      }, 2000);
  } 
    setTimeout(() => {
      this.buttonDisabled = false;
    }, 10000);

  } else {
    this.snack.showError('الرجاء التحقق من إدخال كافة البيانات بشكل صحيح.')
  }      
}


checkIfClickedInside = (event: Event) => {
  let isClickInside = this.modalContent.nativeElement.contains(event.target);
  if (!isClickInside) {
    this.closeModal()
  }    
}


closeModal(){
  this.closeButton.nativeElement.click()
  this.changeStatusToClosed(this.modalName)
  document.removeEventListener("click", this.checkIfClickedInside, true);
}

changeStatusToClosed(modalName) {    
  this.isClosed.emit(modalName);
}

getAuth(): void {
  this.isLoading = true
  this.api.get('auth').subscribe(
    res => {
      this.auth = res
      if(!this.isEdit) {
        if(!this.checkPermission('prm_users_create')) {
          this.snack.showError("لا يسمح لك بإضافة المستخدمين")
          this.router.navigateByUrl("/admin")
          
        }
        this.contractDetails.parent_id = res['client']['id']
      }

      this.isLoading = false
    },
    err => {
      this.snack.showError('There is something wrong. Please check the console!')
      this.isLoading = false
      console.log(err);
    }
  )
}

checkPermission(permissionType: string) {    
  console.log(permissionType, " => ", this.auth['permissions'].includes(permissionType));
  return this.auth['permissions'].includes(permissionType);
}


}

