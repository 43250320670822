import { ContractOverviewModel } from "./../../models/contract-overview";
import { SnackService } from "../../services/snack.service";
import { ContractModel } from "../../models/contract";
import { ApiService } from "../../services/api-service.service";
import { Component, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { formatDate } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { ContractOnlineModel } from "src/app/models/contract-online";

@Component({
  selector: "app-specific-contract",
  templateUrl: "./specific-contract.component.html",
  styleUrls: ["./specific-contract.component.scss"],
})
export class SpecificContractComponent implements OnInit {
  contractDetails: ContractModel;
  contractOverview: ContractOverviewModel;
  onlineContract: ContractOnlineModel[] = [];
  isLoading: boolean;
  isCopied: boolean;
  isEdit: boolean;
  isPasswordHidden: boolean = true;
  profiles: [];
  parents: [];
  groups: [];
  auth: {};
  sites: [];
  generatedpassword: string;
  clipboard: any;

  constructor(
    private api: ApiService,
    private snack: SnackService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private titleService: Title
  ) {
    let id = this.activatedRoute.snapshot.paramMap.get("id");
    let isNum = (value) => /^\d+$/.test(value);
    if (id) {
      if (isNum(id)) {
        this.getContract(id);
        //if (!this.isEdit) {
          //this.refreshdata(id);
        //}

        this.isEdit = true;
      } else {
        this.router.navigateByUrl("/admin");
        this.snack.showError("The id is not valid!");
      }
    } else {
      this.isEdit = false;
    }
  }

  ngOnInit(): void {
    this.getAuth();
    this.getProfiles();
    this.getParents();
    this.getGroups();
    this.getSites();
    this.setInitialValues();
    if (!this.isEdit) {
      this.getLastContractNumber();
    }
    if (!this.isEdit) {
      this.getLastUsername();
    }
    if (!this.isEdit) {
      this.generatePassword();
    }
    this.contractDetails.password = String(this.generatedpassword);
    this.contractDetails.confirm_password = String(this.generatedpassword);
    this.chackpasswordforce();
  }


  setInitialValues(): void {
    this.contractDetails = {
      username: null,
      enabled: 1,
      password: "",
      confirm_password: "",
      profile_id: null,
      parent_id: null,
      group_id: null,
      site_id: null,
      firstname: null,
      lastname: null,
      company: null,
      email: null,
      phone: null,
      city: null,
      address: null,
      mobile: null,
      street: null,
      national_id: null,
      contract_id: "جاري المعالجة ...",
      notes: null,
      auto_renew: 0,
      expiration: formatDate(new Date(), "YYYY-MM-dd HH:mm:ss", "en"),
      simultaneous_sessions: 1,
      static_ip: null,
      mikrotik_winbox_group: null,
      mikrotik_framed_route: null,
      mikrotik_addresslist: null,
      mikrotik_ipv6_prefix: null,
      gps_lat: null,
      gps_lng: null,
    };
  }

  getContract(id) {
    this.isLoading = true;
    this.api.get("user/" + id).subscribe(
      (res) => {
        this.contractDetails = res["data"];
        this.getContractOverview(id);
        this.getOnlineInformation(this.contractDetails.username);
        this.titleService.setTitle(
          `الأفق الرقمي لخدمات الإنترنت - نظام إدارة عقود المشتركين | ${this.contractDetails.contract_id} | ${this.contractDetails.username}`
        );
      },
      (err) => {
        this.router.navigateByUrl("/admin");
        this.snack.showError(
          "There is something wrong. Please check the console!"
        );
        this.isLoading = false;
        console.log(err);
      }
    );
  }

  copyMessage(val: string) {
    let message = `${this.contractOverview.username || ''}\n${this.contractDetails.password || ''}
    \n${this.contractDetails.firstname || ''} ${this.contractDetails.lastname || ''}\n${this.contractDetails.city || ''},${this.contractDetails.address || ''},${this.contractDetails.street || ''}\n${this.contractDetails.phone || ''}\n${this.contractDetails.mobile || ''}`;
  
    if (this.contractDetails.gps_lat !== null && this.contractDetails.gps_lng !== null) {
      message += `\n${this.contractDetails.gps_lat},${this.contractDetails.gps_lng}`;
      message += `\nhttps://www.google.com/maps/search/?api=1&query=${this.contractDetails.gps_lat},${this.contractDetails.gps_lng}`;
    }
  
    const selBox = document.createElement("textarea");
    selBox.style.position = "fixed";
    selBox.style.left = "0";
    selBox.style.top = "0";
    selBox.style.opacity = "0";
    selBox.value = message;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand("copy");
    document.body.removeChild(selBox);
    this.snack.showSuccess("تم نسخ البيانات بنجاح.");
  }

  refreshdata(id) {
    //setInterval(() => {
      this.api.get("user/" + id).subscribe(
        (res) => {
          this.contractDetails = res["data"];
          this.refreshContractOverview(id);
          this.setInitialValues;
          // add this line to update the UI
          setTimeout(() => {
            this.contractDetails = res["data"];
          }, 0);
        },
        (err) => {
          console.log(err);
        }
      );
    //}, 10000);
  }

  refreshContractOverview(id) {
    this.api.get("user/overview/" + id).subscribe(
      (res) => {
        this.contractOverview = res["data"];
        this.contractDetails.password = res["data"]["password"];
        this.contractDetails.confirm_password = res["data"]["password"];
      },
      (err) => {
        this.router.navigateByUrl("/admin");
        this.snack.showError(
          "There is something wrong. Please check the console!"
        );
        console.log(err);
      }
    );
  }

  getContractOverview(id) {
    this.isLoading = true;
    this.api.get("user/overview/" + id).subscribe(
      (res) => {
        this.isLoading = false;
        this.contractOverview = res["data"];
        this.contractDetails.password = res["data"]["password"];
        this.contractDetails.confirm_password = res["data"]["password"];
      },
      (err) => {
        this.router.navigateByUrl("/admin");
        this.snack.showError(
          "There is something wrong. Please check the console!"
        );
        this.isLoading = false;
        console.log(err);
      }
    );
  }

  disconnect(id) {
    this.api.get("user/disconnect/userid/" + this.contractDetails.id).subscribe(
      (res) => {
        if (res["status"] == 200 && res["message"] == "rsp_success") {
          this.snack.showSuccess("تم قطع الأتصال عن المشترك.");
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
        }
      },
      (err) => {
        this.snack.showError(
          "There is something wrong. Please check the console!"
        );
        this.isLoading = false;
        console.log(err);
      }
    );
  }

  getOnlineInformation(username) {
    this.isLoading = true;

    const contractsRequestData = {
      page: 1,
      count: 10,
      sortBy: null,
      direction: "asc",
      search: username,
      columns: ["framedipaddress", "acctsessiontime"],
    };
    this.api.post("index/online", contractsRequestData, true).subscribe(
      (res) => {
        if (res["data"]) {
          res["data"].forEach((element) => {
            if (element.username == username) {
              let item: ContractOnlineModel = element;
              this.onlineContract.push(item);
            }
          });
        }

        // this.onlineContract = res['data']
        // console.log(this.onlineContract);
      },
      (err) => {
        this.router.navigateByUrl("/admin");
        this.snack.showError(
          "There is something wrong. Please check the console!"
        );
        this.isLoading = false;
        console.log(err);
      }
    );
  }

  onSubmit(form: NgForm): void {
    if (form.valid) {
      if (
        this.contractDetails.password != this.contractDetails.confirm_password
      ) {
        this.snack.showError(
          "الرجاء التحقق من إدخال كلمة مرور متطابقة في الحقلين المخصصين."
        );
        return;
      }

      if (this.isEdit) {
        this.editContract(this.contractDetails.id);
      } else {
        this.addContract();
      }
    } else {
      this.snack.showError("الرجاء التحقق من إدخال كافة البيانات بشكل صحيح.");
    }
  }

  addContract() {
    this.isLoading = true;
  
    // Fetch the last contract number
    this.getLastContractNumber();
    
    // Fetch the last username
    this.getLastUsername();
    
    // Proceed after both asynchronous calls are completed
    setTimeout(() => {
      const newContractNumber = Number(this.contractDetails.contract_id);
      const newUsername = this.contractDetails.username;
  
      if (newContractNumber === Number(this.contractDetails.contract_id) &&
          newUsername === this.contractDetails.username) {
        
        this.contractDetails.password = String(this.generatedpassword);
        this.contractDetails.confirm_password = String(this.generatedpassword);
        
        // Make the final API request to add the user
        this.api.post("user", this.contractDetails, true).subscribe(
          (res) => {
            if (res["status"] === 200 && res["message"] === "rsp_save_success") {
              this.snack.showSuccess("تم إضافة المشترك بنجاح");
              this.setInitialValues();
              const id = res.data.id;
              this.router.navigateByUrl("/admin/contract/" + id);
            } else if (res["message"] === "rsp_user_exists") {
              this.snack.showError("اسم الدخول الذي تحاول ادراجه موجود مسبقًا.");
            } else {
              this.snack.showError(res["message"]);
            }
            this.isLoading = false;
          },
          (err) => {
            this.snack.showError(
              "There is something wrong. Please check the console!"
            );
            this.isLoading = false;
            console.log(err);
          }
        );
      } else {
        this.snack.showError(
          "رقم العقد تم إستخدامه من قبل مستخدم اخر، الرجاء المحاولة مجددًا."
        );
        this.contractDetails.contract_id = newContractNumber.toString();
        this.isLoading = false;
      }
    }, 1000); // Timeout ensures that both `getLastContractNumber()` and `getLastUsername()` complete their tasks before continuing.
  }
  
  editContract(id) {
    this.isLoading = true;

    if (this.isEdit && !this.checkPermission("prm_users_update")) {
      this.snack.showError("لا يسمح لك بتعديل المستخدمين");
      this.isLoading = false;
      return false;
    }

    this.api.put("user/" + id, this.contractDetails, true).subscribe(
      (res) => {
        this.snack.showSuccess("تم تعديل بيانات المشترك بنجاح");
        this.getContractOverview(id);
        this.isLoading = false;
      },
      (err) => {
        this.snack.showError(
          "There is something wrong. Please check the console!"
        );
        this.isLoading = false;
        console.log(err);
      }
    );
  }

  getLastContractNumber(): void {
    this.isLoading = true;
    const contractsRequestData = {
      page: 1,
      count: 1,
      sortBy: "contract_id",
      direction: "desc",
    };
    this.api.post("index/user", contractsRequestData, true).subscribe(
      (res) => {
        const lastContractNumber =
          res["data"].length == 0 ? 0 : res["data"][0]["contract_id"];
        const newContractNumber = Number(lastContractNumber) + 1;
        this.contractDetails.contract_id = newContractNumber.toString();
        this.isLoading = false;
      },
      (err) => {
        this.snack.showError(
          "Something goes wrong when fetching the last contract number!"
        );
        console.log(err);
      }
    );
  }

  getLastUsername(): void {
    this.isLoading = true;
    const contractsRequestData = {
      page: 1,
      count: 100, // Fetch more records to ensure we get the right one
      sortBy: "contract_id",
      direction: "desc",
    };
  
    this.api.post("index/user", contractsRequestData, true).subscribe(
      (res) => {
        // Filter to get only usernames that start with "180" or "BNG-LL"
        const validUsernames = res["data"].filter(
          (user) =>
            user["username"].startsWith("180") || user["username"].startsWith("BNG-LL")
        );
  
        // Check if we have any valid usernames
        const LastUsername = validUsernames.length === 0 ? "0" : validUsernames[0]["username"];
        let newUsername: string;
  
        if (LastUsername.startsWith("180")) {
          const numericPart = Number(LastUsername); // Convert the whole number to int
          newUsername = (numericPart + 1).toString(); // Add 1
        } else if (LastUsername.startsWith("BNG-LL")) {
          // Find the last username starting with "180" to add 2 to it
          const last180Username = validUsernames.find((user) =>
            user["username"].startsWith("180")
          );
  
          if (last180Username) {
            const numericPart = Number(last180Username["username"]);
            newUsername = (numericPart + 2).toString(); // Add 2 to the last "180" username
          } else {
            // Handle the case where there is no "180" username found
            newUsername = "0";
          }
        } else {
          newUsername = "0"; // Fallback case
        }
  
        this.contractDetails.username = newUsername;
        this.isLoading = false;
      },
      (err) => {
        this.snack.showError(
          "Something went wrong when fetching the last contract number!"
        );
        console.log(err);
      }
    );
  }
  
  getProfiles(): void {
    this.isLoading = true;
    this.api.get("list/profile/5").subscribe(
      (res) => {
        this.profiles = res["data"];
        this.isLoading = false;
      },
      (err) => {
        this.snack.showError(
          "There is something wrong. Please check the console!"
        );
        this.isLoading = false;
        console.log(err);
      }
    );
  }

  getParents(): void {
    this.isLoading = true;
    this.api.get("index/manager").subscribe(
      (res) => {
        this.parents = res["data"];
        this.isLoading = false;
      },
      (err) => {
        this.snack.showError(
          "There is something wrong. Please check the console!"
        );
        this.isLoading = false;
        console.log(err);
      }
    );
  }

  getGroups(): void {
    this.isLoading = true;
    this.api.get("group").subscribe(
      (res) => {
        this.groups = res["data"];
        this.isLoading = false;
      },
      (err) => {
        this.snack.showError(
          "There is something wrong. Please check the console!"
        );
        this.isLoading = false;
        console.log(err);
      }
    );
  }

  refreshExpiration(): void {
    this.contractDetails.expiration = formatDate(
      new Date(),
      "YYYY-MM-dd HH:mm:ss",
      "en"
    );
  }

  getAuth(): void {
    this.isLoading = true;
    this.api.get("auth").subscribe(
      (res) => {
        this.auth = res;
        if (!this.isEdit) {
          if (!this.checkPermission("prm_users_create")) {
            this.snack.showError("لا يسمح لك بإضافة المستخدمين");
            this.router.navigateByUrl("/admin");
          }
          this.contractDetails.parent_id = res["client"]["id"];
        }
        if (res.client.force_change_password === 1) {
          this.router.navigateByUrl("/changePassword");
        }

        this.isLoading = false;
      },
      (err) => {
        this.snack.showError(
          "There is something wrong. Please check the console!"
        );
        this.isLoading = false;
      }
    );
  }

  checkPermission(permissionType: string) {
    //console.log(permissionType, " => ", this.auth['permissions'].includes(permissionType));
    return this.auth["permissions"].includes(permissionType);
  }

  getSites() {
    this.isLoading = true;
    this.api.get("site").subscribe(
      (res) => {
        this.sites = res["data"];
        this.isLoading = false;
        return true;
      },
      (err) => {
        if (err.errorStatus != 403) {
          this.snack.showError(
            "There is something wrong. Please check the console!"
          );
          this.isLoading = false;
          console.log(err);
        }
        return false;
      }
    );
  }

  showHidePassword() {
    this.isPasswordHidden = !this.isPasswordHidden;
  }
  public generatePassword() {
    this.generatedpassword = Array(8)
      .fill("0123456789")
      .map(function (x) {
        return x[Math.floor(Math.random() * x.length)];
      })
      .join("");
  }

  chackpasswordforce(): void {
    this.isLoading = true;
    this.api.get("auth").subscribe(
      (res) => {
        this.auth = res;
        if (res.client.force_change_password === 1) {
          this.router.navigate(["admin/changePassword"]);
          this.snack.showError("يجب تغيير كلمة السر للمتابعة!");
        }

        this.isLoading = false;
      },
      (err) => {
        this.snack.showError(
          "There is something wrong. Please check the console!"
        );
        this.isLoading = false;
        console.log(err);
      }
    );
  }
}
