import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment.prod';
import * as crypto from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  api: string = environment.API_URL
  private JWT_TOKEN = 'JWT_TOKEN'
  cypKey = environment.PRIVATEKEY

  constructor(public http: HttpClient, public router: Router) { }

  operations = {
    login : (body) => {
      const cypData = crypto.AES.encrypt(JSON.stringify(body), this.cypKey);
      let requestData = {payload: cypData.toString()};

      return this.http.post(this.api + 'login', requestData);
    },
    logout: ()=> {
      localStorage.removeItem(this.JWT_TOKEN);
      this.router.navigateByUrl('/');
    }
  }

  isloggedIn() {
    return !!this.getJwtToken();
  }

  getJwtToken() {    
    return localStorage.getItem(this.JWT_TOKEN);
  }
}
