<div class="d-flex justify-content-center vh-100 align-items-center" *ngIf="isLoading">
    <div class="spinner-border text-alofoq m-3" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    الرجاء الإنتظار ...
  </div>
  <div id="mydiv" class="page position-absolute" *ngIf="!isLoading">
  
    <div class="position-relative" style="width: 90%; margin: 47mm auto 0;">    
      <div class="row">
        <div class="col-12">
          <div class="headline mt-3 pt-1">
            <div class="hedline-text">بيانات العقد</div>
          </div>
        </div>
        <div class="col-6 mt-3">
          <b>رقم العقد</b>
          <input type="text" class="form-control border-0 py-2 mt-1 bg-input" [value]="contractDetails.contract_id || 'غير محدد'" disabled>
        </div>
        <div class="col-6 mt-3">
          <b>نوع الإشتراك</b>
          <input type="text" class="form-control border-0 py-2 mt-1 bg-input" [value]="getDisplayValue() || 'غير محدد'" disabled>
        </div>             
        <div class="col-6 mt-3">
          <b>تاريخ العقد</b>
          <input type="text" class="form-control border-0 py-2 mt-1 bg-input" [value]="contractDetails.created_at | date:'YYYY-MM-dd' || 'غير محدد' " disabled>
        </div>
        <div class="col-6 mt-3">
          <b>اسم الوكيل / مركز الخدمات</b>
          <input type="text" class="form-control border-0 py-2 mt-1 bg-input" [value]="contractOverview.created_by || 'غير محدد'" disabled>
        </div>             
      </div>
  
      <div class="row">
        <div class="col-12">
          <div class="headline mt-5">
            <div class="hedline-text">بيانات المشترك</div>
          </div>
        </div>
        <div class="col-6 mt-4">
          <b>اسم المشترك</b>
          <input type="text" class="form-control border-0 py-2 mt-1 bg-input" [value]="((contractDetails?.firstname + ' ' || '') + (contractDetails?.lastname || '')) || 'غير محدد'" disabled>
        </div>
        <div class="col-6 mt-4">
          <b>اسم الشركة</b>
          <input type="text" class="form-control border-0 py-2 mt-1 bg-input" [value]="contractDetails.company || 'غير محدد'" disabled>
        </div>
        <div class="col-6 mt-4">
          <b>رقم الهوية</b>
          <input type="text" class="form-control border-0 py-2 mt-1 bg-input" [value]="contractDetails.national_id || 'غير محدد'" disabled>
        </div>
        <div class="col-6 mt-4">
          <div class="row">
            <div class="col-6">
              <b>رقم الهاتف 1</b>
              <input type="text" class="form-control border-0 py-2 mt-1 bg-input" [value]="contractDetails.phone || 'غير محدد'" disabled>
            </div>
            <div class="col-6">
              <b>رقم الهاتف 2</b>
              <input type="text" class="form-control border-0 py-2 mt-1 bg-input" [value]="contractDetails.mobile || 'غير محدد'"disabled>
            </div>
          </div>
        </div>
        <div class="col-5 mt-4">
          <b>البريد الإلكتروني</b>
          <input type="text" class="form-control border-0 py-2 mt-1 bg-input" [value]="contractDetails.email || 'غير محدد'" disabled>
        </div>
        <div class="col-7 mt-4">
          <b>العنوان</b>
          <input type="text" class="form-control border-0 py-2 mt-1 bg-input" [value]="(((contractDetails?.city) || '') + ' ' + ((contractDetails?.address) || '') + ' ' + (contractDetails?.street || '')) || 'غير محدد'" disabled>
        </div>
  
      </div>
  
      <div class="row">
        <div class="col-12">
          <div class="headline mt-5">
            <div class="hedline-text">بيانات الحساب</div>
          </div>
        </div>
        <div class="col-6 mt-4">
          <b>اسم المستخدم</b>
          <input type="text" class="form-control border-0 py-2 mt-1 bg-input" [value]="contractDetails.username || 'غير محدد'" disabled>
        </div>
        <div class="col-6 mt-4">
          <b>الرقم السري</b>
          <input type="text" class="form-control border-0 py-2 mt-1 bg-input" [value]="contractOverview.password || 'غير محدد'" disabled>
        </div>             
      </div>
  
    </div>
  
    <!-- OLD CONTRACT DESIGN V2 -->
    <table class="d-none" style="width: 90%; margin: 27mm auto 0;">
  
      <tr>
        <th></th>
        <th style="font-size: 4.7mm; text-align: center;">
          <b>
            Internet Service Contract
            <br>
            عقد إشتراك خدمة إنترنت
            <br><br>
          </b>
        </th>
        <th></th>
      </tr>
  
      <!-- Contract Information -->
      <tr>
        <th style="font-size: 4mm; border-top: 1px solid rgb(0, 0, 0)" colspan="3">
          <b>
            1.
            بيانات العقد
            (Contract Information)
          </b>
        </th>
      </tr>
      <tr>
        <td>رقم العقد</td>
        <td class="text-center font-weight-bold">{{contractDetails.contract_id}}</td>
        <td dir="ltr" class="text-end">Contract ID</td>
      </tr>
      <tr>
        <td>تاريخ إصدار العقد</td>
        <td dir="ltr" class="text-center font-weight-bold">{{contractDetails.created_at | date:'YYYY-MM-dd' }}</td>
        <td dir="ltr" class="text-end">Created On</td>
      </tr>
      <tr>
        <td>اسم الوكيل/مركز الخدمات</td>
        <td class="text-center font-weight-bold">{{contractOverview.created_by || '-'.repeat(70) }}</td>
        <td dir="ltr" class="text-end">Reseller</td>
      </tr>
  
      <tr>
        <td height= "20px" colspan="3"></td>
      </tr>
  
      <tr>
        <th style="font-size: 4mm; border-top: 1px solid rgb(0, 0, 0)" colspan="3">
          <b>
            2.
            بيانات المشترك
            (Subscriber info)
          </b>
        </th>
      </tr>
      <tr>
        <td>اسم المشترك</td>
        <td class="text-center font-weight-bold">{{contractDetails.firstname}} {{contractDetails.lastname}}</td>
        <td dir="ltr" class="text-end">Full Name</td>
      </tr>
      <tr>
        <td>اسم الشركة</td>
        <td class="text-center font-weight-bold">{{contractDetails.company}}</td>
        <td dir="ltr" class="text-end">Company Name</td>
      </tr>
      <tr>
        <td>رقم الهوية</td>
        <td class="text-center font-weight-bold">{{contractDetails.national_id}}</td>
        <td dir="ltr" class="text-end">ID</td>
      </tr>
      <tr>
        <td>رقم الهاتف 1</td>
        <td class="text-center font-weight-bold">{{contractDetails.phone}}</td>
        <td dir="ltr" class="text-end">Mobile Number 1</td>
      </tr>
      <tr>
        <td>رقم الهاتف 2</td>
        <td class="text-center font-weight-bold">{{contractDetails.mobile}}</td>
        <td dir="ltr" class="text-end">Mobile Number 2</td>
      </tr>
      <tr>
        <td>البريد الإلكتروني</td>
        <td class="text-center font-weight-bold">{{contractDetails.email}}</td>
        <td dir="ltr" class="text-end">E-mail</td>
      </tr>
      <tr>
        <td>العنوان</td>
        <td class="text-center font-weight-bold">
          <small>
            <ng-container *ngIf="contractDetails.city">
              {{contractDetails.city}}،
            </ng-container>
            <ng-container *ngIf="contractDetails.address">
              {{contractDetails.address }}،
            </ng-container>
            <ng-container *ngIf="contractDetails.street">
              {{contractDetails.street }}
            </ng-container>
          </small>
        <td dir="ltr" class="text-end">Address</td>
      </tr>
  
      <tr>
        <td height= "20px" colspan="3"></td>
      </tr>
  
      <tr>
        <th style="font-size: 4mm; border-top: 1px solid rgb(0, 0, 0)" colspan="3">
          <b>
            3.
            بيانات حساب المشترك
            (Account info)
          </b>
        </th>
      </tr>
      <tr>
        <td><b>اسم المستخدم</b></td>
        <td class="text-center font-weight-bold">{{contractOverview.username}}</td>
        <td dir="ltr" class="text-end">Username</td>
      </tr>
      <tr>
        <td><b>الرقم السري</b></td>
        <td class="text-center font-weight-bold">{{contractOverview.password}}</td>
        <td dir="ltr" class="text-end">Password</td>
      </tr>
      <tr>
        <td colspan="3" style="height: 25mm"></td>
      </tr>
      <tr>
        <th style="font-size: 4mm; border-top: 1px solid rgb(0, 0, 0); padding-bottom: 0 !important" colspan="3">        
            للإطلاع علي بيانات حسابك يرجي الدخول علي الرابط التالي:      
        </th>
      </tr>
      <tr>
        <th style="font-size: 4mm; padding-top: 0 !important" colspan="2">http://ecare.alofoq.ly </th>
        <th style="font-size: 4mm; text-align: left; padding-top: 0 !important">انظر للخلف</th>
      </tr>
    </table>
  
    <!-- ********************************************************************** -->
  
    <!-- OLD CONTRACT DESIGN V1 -->
    <table class="d-none" style="width: 90%; margin: 15mm auto 0;">
  
      <!-- Customer Details -->
      <tr style="background-color: #adadad;">
        <th style="font-size: 5mm;" colspan="3">
          <b>
            بيانات المشترك
          </b>
        </th>
      </tr>
      <tr>
        <td>اسم المشترك</td>
        <td>{{contractDetails.firstname}} {{contractDetails.lastname}}</td>
        <td dir="ltr" class="text-end">Full Name</td>
      </tr>
      <tr>
        <td>الجنسية</td>
        <td></td>
        <td dir="ltr" class="text-end">Nationality</td>
      </tr>
      <tr>
        <td>رقم البطاقة/جواز السفر</td>
        <td></td>
        <td dir="ltr" class="text-end">Passport / ID</td>
      </tr>
      <tr>
        <td>البريد الإلكتروني</td>
        <td>{{contractDetails.email}}</td>
        <td dir="ltr" class="text-end">E-Mail</td>
      </tr>
      <tr>
        <td>رقم الهاتف</td>
        <td>{{contractDetails.phone}}  {{contractDetails.mobile}}</td>
        <td dir="ltr" class="text-end">Mobile</td>
      </tr>
      <tr>
        <td>العنوان</td>
        <td>{{contractDetails.address}}</td>
        <td dir="ltr" class="text-end">Address</td>
      </tr>
      <tr>
        <td>المدينة</td>
        <td>{{contractDetails.city}}</td>
        <td dir="ltr" class="text-end">City</td>
      </tr>
  
      <tr>
        <td height= "20px" colspan="3"></td>
      </tr>                 
      
      <!-- Service Information -->
      <tr style="background-color: #adadad;">
        <th style="font-size: 5mm;">
          <b>
            بيانات العقد
          </b>
        </th>
        <th style="font-size: 5mm; text-align: left;" colspan="2">
          <b>
            Contract Information
          </b>
        </th>      
      </tr>
      <tr>
        <td>رقم العقد</td>
        <td>{{contractDetails.contract_id}}</td>
        <td dir="ltr" class="text-end">Contract ID</td>
      </tr>
      <tr>
        <td><b>اسم المستخدم</b></td>
        <td><b>{{contractOverview.username}}</b></td>
        <td dir="ltr" class="text-end"><b>Username</b></td>
      </tr>
      <tr>
        <td><b>الرقم السري</b></td>
        <td><b>{{contractOverview.password}}</b></td>
        <td dir="ltr" class="text-end"><b>Password</b></td>
      </tr>
      <tr>
        <td>اسم الباقة</td>
        <td>{{contractOverview.profile_name}}</td>
        <td dir="ltr" class="text-end">Service</td>
      </tr>
  
      <tr>
        <td height= "20px" colspan="3"></td>
      </tr>
  
      <!-- Equipment -->
      <tr style="background-color: #adadad;">
        <th style="font-size: 5mm;" colspan="3">
          <b>
            المعدات
          </b>
        </th>
      </tr>
      <tr>
        <td>رقم ماك المعدة</td>
        <td></td>
        <td dir="ltr" class="text-end">CPE MAC</td>
      </tr>
      <tr>
        <td>نوع الهوائي</td>
        <td></td>
        <td dir="ltr" class="text-end">Antenna</td>
      </tr>    
  
      <tr>
        <td height= "20px" colspan="3"></td>
      </tr>
  
      <!-- Contract Information -->
      <tr style="background-color: #adadad;">
        <th style="font-size: 5mm;" colspan="3">
          <b>
            بيانات العقد
          </b>
        </th>
      </tr>
      <tr>
        <td>اسم الوكيل/مركز الخدمات</td>
        <td>{{contractOverview.created_by || '-'.repeat(70) }}</td>
        <td dir="ltr" class="text-end">Reseller</td>
      </tr>
      <tr>
        <td>تاريخ إصدار العقد</td>
        <td dir="ltr">{{contractDetails.created_at | date:'YYYY-MM-dd' }}</td>
        <td dir="ltr" class="text-end">Date</td>
      </tr>
      <tr>
        <td>مدة الضمان</td>
        <td dir="ltr">شهر من تاريخ الشراء</td>
        <td dir="ltr" class="text-end">warranty period</td>
      </tr>
      <tr>
        <td height= "40px" colspan="3"></td>
      </tr>
  
      <tr>
        <td height= "10px" colspan="3">
          التوقيع
          <br>
          .................................
        </td>
      </tr>
    </table>
  
  
  </div>